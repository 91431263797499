@import "utils/variables";

.campaign-hamburger-container {
  display: none;
  background: unset;

  @media (max-width: 768px) {
    display: block;
  }
}

.campaign-hamburger-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.campaign-hamburger-menu {
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  overflow: hidden;
  position: absolute;
  top:10%;
  left:0;
  right:0;
  background-color: white;


  .campaign-hamburger-text {
    color: $dark_grey;
    background: $primary_green;
    width: fit-content;
    margin: 3.125rem auto 0.625rem;
    padding: 0.625rem 1.875rem;
    border-radius: 1.25rem;
    font-weight: 700;
  }

  button {
    margin: 0 0.625rem;
  }
}

.campaign-hamburger-menu[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.campaign-hamburger-shop-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 1rem 0.625rem 1.25rem;

  &:hover {
    cursor: pointer;
  }
}

.campaign-hamburger-shop {
  overflow: hidden;
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  background: $hover_green;
  padding-left: 1.875rem;

  a {
    color: $black;
  }

  p {
    margin: 1.25rem 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.campaign-hamburger-shop[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.campaign-hamburger-shop > p {
  cursor: pointer;
}

.campaign-hamburger-language-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1rem 0.625rem 1.25rem;

  &:hover {
    cursor: pointer;
  }
}

.campaign-hamburger-language > p {
  cursor: pointer;
}

.campaign-hamburger-language {
  overflow: hidden;
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  background: $hover_green;
  padding-left: 1.875rem;

  a {
    color: $black;
  }

  p {
    margin: 1.25rem 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.campaign-hamburger-language[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.campaign-hamburger-carrot {
  transition: 0.5s all;
}

.campaign-hamburger-carrot[aria-expanded="false"] {
  transform: rotate(0.5turn);
}

.campaign-hamburger-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1rem 0.625rem 1.25rem;
  cursor: pointer;
  a {
    color: $black;
  }
}
