@import "utils/variables";

.terms-header {
  background-image: url("../../assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 96%;
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h1 {
    font-size: 64px;
    line-height: 72px;
    text-align: center;

    @media (max-width: 800px) {
      font-size: 48px;
      line-height: 56px;
    }
  }
}

.terms-container {
  width: 900px;
  margin: 0 auto;
  margin-top: 80px;

  @media (max-width: 950px) {
    width: 90%;
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
    margin: 40px 0;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin: 20px 0;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0;
    min-height: 34px;
  }

  a {
    color: $secondary_forest;
    font-size: 16px;
    line-height: 22px;

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    padding: 0;
    margin: 20px 0;

    li {
      list-style: none;
    }
  }

  ol {
    li {
      margin-top: 1.5rem;
    }
  }
}
