@import "utils/variables.scss";

.single-choice-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .question {
    display: flex;
    column-gap: 0.5rem;
    font-weight: bold;
  }

  .answers {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .radio_btn_wrapper {
      label {
        display: inline-flex;
        align-items: center;
        color : black !important;
        font-weight: normal !important;
        &:before {
          content: '';
          display: block;
          height: 1.25em;
          min-height: 1.25em;
          width: 1.25em;
          min-width: 1.25em;
          border: solid 1px $secondary_forest;
          border-radius: 50%;
          margin-right: 0.5em;
          transition: border ease-in 150ms, box-shadow ease-in 150ms;
        }
      }
      input[type="radio"] {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px !important;
        overflow: hidden;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
      }
      input[type="radio"]:checked + label::before {
        background: radial-gradient(0.75em circle at center, $secondary_forest 50%, transparent 55%);
        border-color: $secondary_forest;
      }
      input[type="radio"]:checked + label,
      input[type="radio"]:focus + label {
        color: $secondary_forest;
      }

      input[type="radio"]:focus + label::before {
        border-color: $secondary_forest;
      }
    }
  }
}
.qnormal > .question {
  font-weight: normal !important;
}