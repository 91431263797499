@import "utils/variables";

.special-offer-container {
  width: 40rem;
  text-align: center;
  padding: 80px;
  box-sizing: border-box;

  @media (max-width: 680px) {
    padding: 40px 10px;
    width: 100%;
  }

  h2 {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    padding: 0 1.25rem;

    @media screen and (max-width: 760px) {
      padding: 0 0.35rem;
    }

    a {
      color: $secondary-forest;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
