@import "utils/variables";

.stepProgress_container {
  display: flex;
  margin-top: 3rem;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 3.4rem);
  margin-left: 1.7rem;

  @media screen and (max-width: 48rem) {
    width: 95%;
    margin-left: 0;
  }

  .progress {
    width: 0%;
    height: 2px;
    background-color: $primary_green;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    transform: translateY(-50%);
    background-color: $gray;
    z-index: -1;
  }

  .progress_step {
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50%;
    border: 2px solid $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $gray;
    transition: 0.5s ease;
  }

  .completed {
    background-color: $primary_green;
    border-color: $primary_green;
    color: $black;
    font-weight: 700;
  }
  .progress_step {
    position: relative;
    div.step-title {
      position: absolute;
      top: calc(100% + 0.75rem);
      font-size: 16px;
      width: 160px;
      text-align: center;
      font-family: "WalsheimBold";
      display: inline-block;

      @media screen and (max-width: 65.625rem) {
        width: 100px;
      }
    }
  }
  .progress-step-active {
    color: $black;
    font-weight: 700;
    border-color: $primary_green;
  }
}
