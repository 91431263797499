@import "utils/variables";

.video-player {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 1rem;

  .video-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    img {
      opacity: 1;
      height: 150px;
      width: 150px;
      position: absolute;
      z-index: 1;

      @media (max-width: 1200px) {
        height: 100px;
        width: 100px;
      }

      @media (max-width: 510px) {
        height: 60px;
        width: 60px;
      }
    }

    .video-player-overlay-text {
      position: absolute;
      color: $white;
      z-index: 1;
      left: 13%;
      font-size: 48px;
      line-height: 54px;
      width: 400px;

      @media (max-width: 1250px) {
        left: 10%;
        width: 400px;
      }

      @media (max-width: 800px) {
        font-size: 30px;
        line-height: 32px;
        left: 30px;
        width: 200px;
      }

      @media (max-width: 510px) {
        font-size: 20px;
        line-height: 24px;

        width: 150px;
      }

      @media (max-width: 400px) {
        left: 10px;
      }
    }

    .overlay-bg {
      background: $black;
      height: 100%;
      width: 100%;
    }
  }

  div {
    div {
      iframe {
        width: 300%;
        height: 100%;
        /* ...and bring it back again */
        margin-left: -100%;
        min-height: 20rem;
      }
    }
  }
}
