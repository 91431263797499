@import "utils/variables";

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .layout-outlet-container {
    flex: 1;
  }

  .skip-link {
    background: $primary_green;
    color: #000 !important;
    font-weight: 700;
    left: 50%;
    padding: 4px;
    position: absolute;
    transform: translateY(-100%);
    z-index: 50;
    opacity: 0;
  }

  .skip-link:focus {
    transform: translateY(0%);
    opacity: 1;
  }
}
.grecaptcha-badge {
  visibility: hidden;
  right: 0 !important;
}
