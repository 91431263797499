@import "utils/variables.scss";

.radioBtn_container {
  margin-top: 0.25rem;
  input[type="radio"] {
    appearance: none;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: $secondary_forest;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $secondary_forest;
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
}
