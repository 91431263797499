@import "utils/variables";

.faq-container {
  .vertical-split {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    img {
      height: 100%;
    }

    div {
      padding-right: 5rem;
    }
  }

  .faq-header {
    background-image: url("../../../assets/backgrounds/gradient.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 25rem;
    width: 96%;
    border-radius: 1rem;
    margin: 0.625rem auto 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    box-sizing: border-box;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    div {
      position: relative;
      margin: 2.5rem 0;

      .faq-search-icon {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
      }

      input {
        width: 37.5rem;
        border-radius: 1.875rem;
        border: none;
        padding: 0.625rem 1.875rem 0.625rem 1.875rem;
        font-size: 1rem;
        line-height: 1.375rem;

        @media (max-width: 768px) {
          width: 18.75rem;
        }

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }

  .faq-questions-container {
    margin: 0 auto;
    width: 96%;

    h2 {
      font-size: 4rem;
      line-height: 4.5rem;

      @media (max-width: 768px) {
        font-size: 3rem;
        line-height: 3.5rem;
      }

      @media (max-width: 480px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }

  .szh-accordion {
    margin: 5rem 1.25rem;

    @media (max-width: 768px) {
      margin: 3rem 1.25rem;
    }

    @media (max-width: 480px) {
      margin: 2rem 1.25rem;
    }

    p {
      margin: 1rem;
    }

    h2 {
      width: 50rem;
      font-size: 4rem;
      line-height: 4.5rem;
      margin: 0 auto;

      @media (max-width: 1024px) {
        font-size: 2rem;
        line-height: 2.5rem;
        width: 90%;
      }
    }
  }

  .faq-download-container {
    margin: 0 auto;
    width: 50rem;

    @media (max-width: 1024px) {
      width: 90%;
    }

    .faq-download-title {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  .faq-search-bar-container {
    display: flex;
    align-items: center;
    justify-content: stretch;
    max-width: 32.75rem;
    width: 100%;

    @media  (max-width: 768px){
      max-width: 22.75rem;
    }

    .faq-dropdown {
      position: absolute;
      top: 0.125rem;
      left: 1.125rem;
      background: $white;
      border-radius: 0 0 1rem 1rem;
      width: 30.5rem;
      max-width: 94%;
      z-index: 100;

      @media (max-width: 768px) {
        width: 20rem;
      }

      @media (max-width: 480px) {
        width: 90%;
      }

      p {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        text-align: left;

        &:last-child {
          border-radius: 0 0 1rem 1rem;
        }

        &:hover, &:focus {
          background: $hover_green;
          cursor: pointer;
        }
      }
    }
  }
}
