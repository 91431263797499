.modal_container_mobile {
  display: flex;
  flex-direction: column;
  max-height: 35rem;

  .header_container {
    display: flex;
    padding: 2.1875rem 3.125rem 0 3.125rem;

    h3 {
      margin-bottom: 1.25rem;
    }
  }

  .header_line {
    border-bottom: 1px solid #9d9d9d;
    height: 1px;
    width: 100%;
  }

  // CHANNELS
  .modalContents {
    padding: 1.5625rem 3.125rem 2.1875rem 3.125rem;
    overflow: auto;
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;

    @media screen and (max-width: 48rem) {
      grid-template-columns: 1fr;
      padding: 1.5625rem 1.5625rem 2.1875rem 1.5625rem;
      row-gap: 0.9375rem;
    }

    .channel_container {
      display: flex;
      flex-direction: column;

      .channel_content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: solid 1px #9d9d9d;
        height: 100%;
        padding: 0 1.5rem 1.5rem 0;

        div {
          width: 35%;
          margin-right: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 48rem) {
            width: 30%;
          }
        }
        .channel_icon {
          max-width: 100%;
          height: auto;
        }
        .channel_name {
          font-size: 0.875rem;
        }
      }
    }
  }

  //LONG DISTANCE
  .modalContents_longDistance {
    padding: 25px 50px 35px 50px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 25px;
    width: 62.5rem;
    max-width: 62.5rem;

    @media screen and (min-width: 64rem) and (max-width: 80rem) {
      width: 50rem;
    }

    @media screen and (min-width: 48rem) and (max-width: 64rem) {
      width: 35rem;
    }

    @media screen and (max-width: 48rem) {
      display: flex;
      flex-direction: column;
      width: auto;
    }

    .longDistance_content_container {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      max-width: 62.5rem;

      .longDistance_content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        @media screen and (max-width: 48rem) {
          flex-direction: column;
        }
      }

      .longDistance_line {
        border-bottom: 1px solid #9d9d9d;
        height: 1px;
        width: 100%;
      }
    }
  }
}
