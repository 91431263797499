@import "utils/variables";

.start-modal-container {
  background-color: $white;
  position: absolute;
  z-index: 21;
  top: 3.75rem;
  right: 6.25rem;
  border-radius: 1.375rem;
  padding: 1.25rem;
  box-shadow: 0 0.25rem 0.625rem rgb(0 0 0 / 0.2);

  @media (max-width: 480px) {
    right: 0.625rem;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.375rem;
      margin-bottom: 0.375rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
    width: 18.75rem;

    @media (max-width: 480px) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      width: 12.5rem;
    }
  }

  .start-modal-close-container {
    display: flex;
    justify-content: flex-end;

    .start-modal-close-button {
      margin-top: 1rem;
      color: $secondary-forest;
      border: 0.125rem solid $secondary-forest;
      width: fit-content;
      padding: 0.375rem 0.625rem;
      border-radius: 1.375rem;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 480px) {
        margin-top: 0.375rem;
      }
    }
  }
}

.start-modal-triangle {
  background-color: $white;
  height: 1.875rem;
  width: 1.875rem;
  transform: rotate(45deg);
  position: absolute;
  top: 2.75rem;
  right: 8.125rem;
  z-index: 22;

  @media (max-width: 390px) {
    right: 20%;
  }
}
.start-modal-triangle-back {
  background-color: $white;
  height: 1.875rem;
  width: 1.875rem;
  transform: rotate(45deg);
  position: absolute;
  top: 2.75rem;
  right: 8.125rem;
  z-index: 20;
  box-shadow: 0 0.25rem 0.625rem rgb(0 0 0 / 0.2);

  @media (max-width: 480px) {
    right: 20%;
  }
}
