@import "utils/variables";

.survey-navbar-container {
  position: relative;
  width: 90%;
  padding: 1.5rem 0;

  @media (max-width: 925px) {
    justify-content: center;
  }
}

.survey-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0 4rem;
  flex: 1;
}

.survey-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    border: 1px #E8E8E8 solid;
    margin-bottom: 3rem;
  .survey-wrapper {
    .survey-content-header {
      padding: 2rem;
      border-bottom: 1px solid #E8E8E8;
    }
    .survey-content-blurb {
      padding: 2rem;
    }
    .survey {
      padding: 0 2rem 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      margin-bottom: 2rem;
      .answers {
        .radio_btn_wrapper {
          display: flex;
          column-gap: 0.5rem;
        }
      }
    }
    .follow-up {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      padding: 2rem;
      margin-top: 2rem ;
      margin-bottom: 2rem ;
      .consent {
        display: flex;
        column-gap: 0.5rem;
        margin: 1rem 0;
      }
    }
    .submit-responses {
      padding: 2rem;
      .submit-btn {
        display: flex;
        justify-content: flex-end;
        button {
          height: 100%;
          padding: 12px 16px;
          background: #528B52;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14);
          border-radius: 8px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          gap: 10px;
          display: inline-flex;
          cursor: pointer;
          color: white;
          text-transform: uppercase;
          font-weight: bolder;
          border: none;
          min-width: 10rem;
          &:disabled {
            background: grey;
          }
        }
      }
    }
  }
  .survey-success-wrapper {
    .survey-success-header {
      padding: 2rem;
      border-bottom: 1px solid #E8E8E8;
    }
    .survey-success-content {
      padding: 2rem;
    }
    .survey-success-social {
      padding: 0 2rem 2rem;
      font-weight: bolder;
      .footer-social-container {
        margin-top: 1rem;
        display: flex;
        column-gap: 1rem;
      }
    }
  }
}

.survey_warning_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .modal-header {
    border-bottom: 1px solid #E8E8E8;
    padding: 2rem 0;
    h2 {
      margin: 0 1rem;
    }
  }
  .modal-content {
    padding: 2rem 0;
    div {
      margin: 0 1rem;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 1rem;
    column-gap: 0.5rem;
    .go-back-btn {
      background: transparent;
      padding: 0.5rem;
      color: $darker_green;
      text-transform: uppercase;
      font-weight: bolder;
      border: 1px solid $darker_green;
      border-radius: 0.4rem;
    }
    .exit-btn {
      background: transparent;
      padding: 0.5rem;
      color: white;
      text-transform: uppercase;
      font-weight: bolder;
      background: $error;
      border: none;
      border-radius: 0.4rem;
    }
  }
}
.error {
  color: red;
}
.q8_other {
  display: flex;
  column-gap: 1rem;
}
