@import "utils/variables";

.movie-marquee-container-desktop {
  display: flex;
  height: 25rem;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }

  .movie-marquee-up {
    transform: translate(0, 9.375rem);
    margin-top: -9.375rem;
  }

  .movie-marquee-down {
    transform: translate(0, -9.375rem);
    margin-bottom: -9.375rem;
  }
}

.movie-marquee-container-mobile {
  display: none;
  overflow: hidden;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
