@import "utils/variables";

//  CREDIT CARD INFO
.stepThree_creditInfo_form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex: 1;
  margin-top: 25px;
  border: 0.125rem solid black;
  border-radius: 22px;
  padding: 1.875rem 2.1875rem;

  @media screen and (max-width: 48rem) {
    padding: 0;
  }

  .form_title {
    width: 100%;
    @media screen and (max-width: 48rem) {
      border-bottom: 2px solid black;
      width: 100%;
      padding: 1rem 1.875rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    font-family: "WalsheimRegular";

    @media screen and (max-width: 48rem) {
      padding: 0rem 1rem 1rem 1rem;
    }

    .form_sub_title {
      width: 100%;
      display: flex;

      span {
        margin-bottom: 1rem;
      }
      .stepThree_safe_and_secure {
        margin-top: 30px;

        .stepThree_lock {
          margin-right: 10px;
        }
      }
    }

    .stepThree_billing_address_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1.875rem;

      .stepThree_billing_address {
        width: 100%;
      }
      .stepThree_billing_inner_wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;

        &:nth-last-of-type(1) {
          margin-top: 1.25rem;
        }
        &:nth-last-of-type(2) {
          margin-top: 1.5625rem;
        }

        @media screen and (max-width: 48rem) {
          flex-direction: column;
          margin-top: 0;

          &:nth-last-of-type(1) {
            margin-top: 0;
          }
          &:nth-last-of-type(2) {
            margin-top: 0;
          }
        }

        .stepThree_billing_content {
          flex: 1;

          @media screen and (max-width: 48rem) {
            width: 100%;
            margin-top: 1.375rem;
          }

          &:nth-child(1) {
            margin-right: 1.5625rem;
          }
        }
      }
    }
    // HORIZONTAL RULE
    .horizontal_rule {
      margin-top: 1.875rem;
    }

    div {
      display: flex;
      flex-direction: column;
      label {
        font-size: 12px;
        color: $btn_dark;
      }
      input {
        border-width: 1px;
        border-color: $input_border;
        border-radius: 8px;
        padding: 8px;
        box-shadow: none;
        border-style: solid;
      }
    }
    //CARD INFO
    .stepThree_card_info_container {
      @media screen and (max-width: 48rem) {
        margin-top: 1.5625rem;
      }

      .stepThree_card_number_expiry_cvv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 25px;

        .expiry_security_wrapper {
          display: flex;
          flex-direction: row;
          flex: 1;
          flex-shrink: 0;

          @media screen and (max-width: 48rem) {
            margin-top: 1.875rem;
          }

          .stepThree_expiry_date {
            flex: 0.5;
            margin-left: 16px;

            input {
              width: 30%;
              min-width: 2rem;
              &:nth-child(2) {
                margin-left: 8px;
              }
            }

            @media screen and (max-width: 48rem) {
              margin-left: 0;
            }
          }

          .stepThree_cvv {
            flex: 1;
            margin-left: 16px;
          }
        }

        @media screen and (max-width: 48rem) {
          flex-direction: column;
          margin-top: 1.25rem;
        }

        .stepThree_card_name {
          flex: 1;
        }
        .stepThree_card_number {
          flex: 1;
        }
      }
    }
    //BILLING ADDRESS
    .stepThree_billing_container {
      margin-top: 30px;

      @media screen and (max-width: 48rem) {
        margin-top: 2.1875rem;
      }
      p {
        margin-top: 6px;
      }
      .stepThree_billing_checkbox {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        input {
          border-radius: 1.6px !important;
          margin-top: 3.2px;
        }
        .checkmark {
          margin-top: 3.2px;
        }
        p {
          margin: 0 0 0 16px;
        }
      }
    }

    .stepThree_add_card {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 15.5rem;
      background-color: transparent;
      color: $secondary_forest;
      border: 0.125rem solid $secondary_forest;
      .plus_icon {
        margin-right: 0.625rem;
      }
    }

    .stepThree_active_card_container {
      width: 100%;
      .stepThree_active_card_wrapper {
        display: flex;
        flex-direction: row;
        border: 2px solid black;
        width: 100%;
        margin-top: 0.9375rem;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 1rem;
        justify-content: space-between;
        .card_info {
          display: flex;
          flex-direction: row;
          align-items: center;

          p {
            // width: 9.375rem;
          }

          .card_icon {
            width: 55px;
            // height: 20px;
            margin-right: 1rem;
          }
        }
        .card_actions_wrapepr {
          display: flex;
          flex-direction: row;
          width: 40%;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;

          .card_action {
            color: $secondary_forest;
            text-decoration: underline;
            &:hover {
              cursor: pointer;
            }
          }

          .devider {
            border-right: 1px solid #201f1f;
            height: 1.1rem;
          }
        }
      }
    }

    // FORM BUTTONS
    .stepThree_form_buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;

      @media screen and (max-width: 48rem) {
        display: flex;
        flex-direction: column;
        margin-top: 0.75rem;
      }

      .stepThree_form_cancel_btn {
        background-color: transparent;
        color: $secondary_forest;
        max-width: 20%;

        @media screen and (max-width: 48rem) {
          max-width: 100%;
          margin-bottom: 1rem;
          // border: 0.125rem solid $secondary_forest;
        }
      }
      .stepThree_form_save_btn {
        display: flex;
        justify-content: center;
        background-color: transparent;
        color: $secondary_forest;
        border: 0.125rem solid $secondary_forest;
      }
    }
  }
}
