@import "utils/variables";

.rfm-marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;

  span {
    font-family: AghartiRegular;
    font-size: 8.75rem;
    line-height: 8.75rem;
    letter-spacing: 6px;
    display: flex;
    flex-wrap: nowrap;
    min-width: fit-content;
    margin-right: 0.625rem;

    @media (max-width: 48rem) {
      font-size: 4.375rem;
      line-height: 4.375rem;
    }
  }
}
