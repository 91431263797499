@import "utils/variables";

.career-hamburger-container {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 5;
  background: $white;

  @media (max-width: 925px) {
    display: flex;
    flex-direction: column;
  }

  .career-hamburger-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    border-bottom: 1px solid $black;

    .career-hamburger-logo-container {
      display: flex;
      align-items: center;

      p {
        font-size: 25px;
        line-height: 22px;
        margin-left: 8px;
      }
    }
  }

  .career-hamburger-menu {
    max-height: 1000px;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 20px;
  }

  .career-hamburger-menu[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.5s ease-in-out;
  }

  .career-hamburger-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
    }
  }
}
