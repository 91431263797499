@import "utils/variables";

.thanks-modal-container {
  padding: 2.5rem 8.75rem;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 20rem;

  @media (max-width: 43.75rem) {
    padding: 2.5rem 0.625rem;
  }

  .thanks-modal-header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    margin-bottom: 1rem;

    h2 {
      font-size: 1.625rem;
      line-height: 1.9375rem;
    }
  }

  .thanks-modal-social-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;

    .thanks-modal-social-icon-container {
      display: flex;
      gap: 2rem;
      margin: 0 auto;
      max-width: 16.25rem;
    }
  }

  h2 {
    margin-bottom: 0.9375rem;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.3125rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.3125rem;
  }
}
