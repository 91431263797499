@import "utils/variables";

.szh-accordion__item {
  border-bottom: 1px solid $input_border;
  width: 50rem;
  padding-bottom: 1rem;
  margin: 1rem 0.125rem 0;

  @media (max-width: 1024px) {
    width: 90%;
  }

  .faq-accordion-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }
}

.szh-accordion__item[aria-center="true"] {
  margin: 2.5rem auto;
}

.szh-accordion__item-btn {
  display: flex;
  background: transparent;
  border: none;
  align-items: center;

  div {
    position: relative;
    left: -0.625rem;
  }
}

.szh-accordion__item-content {
  transition: height 0.2s ease-in-out;

  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

.szh-accordion__item-panel {
  margin-left: 1.875rem;
  padding-top: 0.625rem;

  img {
    margin: 1.25rem 0 0.625rem;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}
