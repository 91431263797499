@import "utils/variables";

.breadcrumbs-container {
  padding: 0.375rem 1.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .item {
      a {
        font-size: 0.75rem;
        text-decoration: underline;
        color: #00663E;
        line-height: 1rem;
        &:hover {
          color: #00482C !important;
        }
      }
      &.active {
        font-size: 0.75rem;
        text-decoration: none;
        color: $black;
        font-family: "WalsheimBold";
        line-height: 1rem;
      }
    }
    .caret {
      margin: 0.25rem;
    }
  }
}
