@import "utils/variables";

.commercial-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 4.625rem 4rem 1.875rem;

  @media (max-width: 480px) {
    margin: 1.25rem 0.625rem;
  }

  .commercial-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.625rem;
      line-height: 2rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    p:last-child:not(.commercial-modal-text) {
      margin-bottom: 2rem;
    }

    .commercial-modal-text {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
      margin-top: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
