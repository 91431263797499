@import "utils/variables";

.step_five_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 2px solid $border_dark;
  min-height: 550px;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 48rem) and (max-width: 78rem) {
    margin-top: 1rem;
  }

  @media screen and (max-width: 48rem) {
    margin-left: 1rem;
    min-height: fit-content;
  }

  .step_five_text_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;

    h2 {
      margin-bottom: 1.1875rem;
    }

    button {
      margin-top: 2.1875rem;
      width: 185px;
    }
    .connect_text {
      text-align: center;
      width: 35%;
    }

    .follow {
      margin-top: 3.125rem;

      @media screen and (max-width: 48rem) {
        margin-top: 2.5rem;
      }
    }
    .social_icons {
      display: flex;
      margin-top: 1rem;
      gap: 1rem;

      @media screen and (max-width: 48rem) {
        margin-top: 0.5rem;
      }

      a {
        &:hover {
          transform: scale(1.05);
          cursor: pointer;
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }

    @media screen and (max-width: 48rem) {
      bottom: 1rem;
      left: 0;
      h2 {
        margin-top: 1.25rem;
        font-size: 1.25rem;
        width: 65%;
        text-align: center;
      }

      .connect_text {
        width: 85%;
      }
    }
  }

  .confetti_wrapper {
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;

    @media screen and (max-width: 48rem) {
      padding-top: 2rem;
    }

    h2 {
      margin-top: 2.5rem;

      @media screen and (max-width: 48rem) {
        line-height: 30px;
        text-align: center;
      }
    }
    .connect_text {
      max-width: 375px;
      margin-top: 1.25rem;
      text-align: center;
    }

    .mt-1 {
      margin-top: 1.25rem;
    }
    span {
      text-align: center;
    }
  }
}
