@import "utils/variables";

.search-result-container {
  margin: 5rem 0 0;

  .search-result-header {
    p {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  .no-results-tips {
    margin: 2rem 0;
  }

  .search-result-content {
    margin: 3.75rem auto;
  }

  .search-result-page-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 0.625rem;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }

    p {
      margin: 0 0.125rem;
    }
  }
}
