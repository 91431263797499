@import "utils/variables";

.modal_container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 101;
  border-radius: 1rem;
  position: relative;
  margin: 0 1rem;

  .close_modal_btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }
  }

  // YOUR ADDRESS COMPONENT
  .address_warning_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 540px;
    height: 250px;

    @media screen and (max-width: 48rem) {
      width: auto;
      height: auto;
      padding: 2rem;
    }

    .address_warning_btn_wrapper {
      display: flex;
      gap: 0.75rem;

      .address_warning_cancel_btn {
        border: $secondary_forest 2px solid;
        background-color: $white;
        color: $secondary_forest;
        font-size: 1rem;
        padding: 8px 24px;
        border-radius: 22px;
        margin-right: 1rem;
        width: 120px;
        height: 40px;
        &:hover {
          cursor: pointer;
        }
      }
      .address_warning_change_btn {
        border: none;
        background-color: $primary_green;
        color: $btn_dark;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 22px;
        width: 175px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  //TOOLTIP COMPONENT
  .tooltip_content_mobile {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    padding-top: 3rem;
    border-radius: 20px;
    background-color: $tooltip_background;
    font-family: "WalsheimRegular";

    span {
      font-family: "WalsheimRegular";
    }

    div {
      a {
        color: $secondary_forest;
        text-decoration: underline;
      }
    }
    p {
      &:nth-child(1) {
        margin-bottom: 1rem;
      }
      a {
        color: $secondary_forest;
        text-decoration: underline;
      }
    }
  }
}
