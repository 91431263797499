@import "utils/variables";

.search-bar-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 0.625rem;

  @media (max-width: 768px) {
    display: none;
  }

  .search-bar-icon {
    position: absolute;
    left: 0.5rem;
    top: 0.375rem;
  }

  .hidden {
    display: none !important;
  }

  .search-bar {
    width: 5.5rem;
    height: 1.5rem;
    border-radius: 1.25rem;
    border: none;
    background: $dark_grey;
    color: $white;
    padding-left: 1.875rem;
    font-size: 1rem;
    @media (max-width: 768px) {
      padding-left: 2rem;
    }
    &:focus-within {
      width: 12.5rem;
      border: 1px solid $white;
      &::placeholder {
        opacity: 0.25;
      }
    }
    &::placeholder {
      color: $white;
      @media (max-width: 768px) {
        font-size: 1rem;
        color: $black;
      }
    }
  }
}

.search-bar-container[aria-pressed="true"] {
  display: block;
  margin: 0.375rem 0 0.625rem 0.75rem;

  .search-bar {
    background: $white;
    color: $dark_grey;
  }
}
