.footer-wrapper {
  margin-top: 1.25rem;
  margin-bottom: 5rem;
  .black-line {
    border-top: black solid 2px;
    margin-bottom: 2rem;
    @media (max-width: 1024px){
      margin: 0 0.5rem 2.5rem;
    }
    &.bottom-line {
      display: none;
        @media (max-width: 1024px){
          display: block;
          margin: 2rem auto;
        }
    }
  }
  footer {
    .footer-flex {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;

      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        row-gap: 3rem;
        align-items: center;
        p {
          text-align: center
        }
      }

      .logo {
        @media (max-width: 1024px) {
          margin-bottom: 2rem;
        }
      }
      .copyright {
        display: none;
        @media (max-width: 1024px) {
          display: block;
          margin: 3rem auto 1.5rem;
        }
      }
  }
  }


}