.monerisFrame {
  overflow: hidden;
  height: 175px;
  border: none;
  margin-top: 1rem;

  #monerisDataInput {
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
}
