@import "utils/variables";

.banner-container {
  display: flex;
  justify-content: center;
  position: relative;
  background: $primary_green;
  color: $dark_grey;

  .banner-text {
    display: flex;
    padding: 10px;

    p {
      margin-right: 8px;
    }

    a {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .banner-close-container {
    display: flex;
    position: absolute;
    right: 0px;
    top: -2px;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}
