@import "utils/variables.scss";

.realityOfTheInternet_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem 1rem;

  .banner {
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    background: linear-gradient(
      to right,
      rgba(208, 232, 138, 1),
      rgba(176, 208, 195, 1)
    );

    h1 {
      font-size: 4rem;
      color: #fff;
      text-align: center;
      line-height: 4.5rem;
      width: 35rem;
      color: #000;

      @media screen and (max-width: 48rem) {
        font-size: 2rem;
        line-height: 2.5rem;
        width: 20rem;
      }
    }

    h2 {
      font-size: rem;
      color: #fff;
      text-align: center;
      color: #000;
      font-size: 2rem;
      font-family: WalsheimRegular;
      font-weight: 400;
      margin-top: 50px;

      @media screen and (max-width: 48rem) {
        font-size: 1.25rem;
      }
    }
  }

  .realityOfTheInternet_content_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 65%;
    margin-top: 6rem;

    @media screen and (max-width: 48rem) {
      h2 {
        line-height: 2.5rem;
      }
      max-width: 100%;
    }

    .realityOfTheInternet_fastTruth {
      h2 {
        font-size: 2rem;
      }
      p {
        margin-top: 1rem;
      }
    }

    .realityOfTheInternet_whatsGiven {
      margin-top: 3.75rem;

      h2 {
        font-size: 2rem;
      }
      ol {
        margin-left: 5rem;
        padding: 0;

        @media screen and (max-width: 48rem) {
          margin-left: 2.5rem;
        }

        li {
          list-style-position: outside;
          margin-top: 2rem;

          &::marker {
            font-family: "WalsheimRegular", sans-serif;
            font-weight: 400;
            font-size: 2rem;
          }

          h2 {
            font-family: "WalsheimRegular";
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.5rem;
          }
          p {
            margin-top: 1rem;
          }
        }
      }
    }

    .realityOfTheInternet_howMuchYouNeed {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 3.75rem;

      h2 {
        font-size: 32px;
      }

      img {
        width: 100%;
        height: auto;
        margin-top: 1rem;
      }
    }

    .realityOfTheInternet_howDifferent {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 3.75rem;
      h2 {
        font-size: 2rem;
      }

      p {
        margin-top: 1.25rem;
      }
      div {
        margin-top: 1.15rem;
        a {
          color: $secondary-forest;
        }
      }
    }

    .realityOfTheInternet_contact_container {
      margin-top: 5.625rem;
      text-align: center;

      @media screen and (max-width: 48rem) {
        margin-top: 3.125rem;
      }
    }
  }
}
