@import "utils/variables";

.technical-header {
  background-image: url("../../assets/backgrounds/Tech-Header.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  height: 43.75rem;
  width: 98%;
  margin: 0 auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  @media (max-width: 1024px) {
    padding-left: 1.25rem;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1.25rem;
    background-image: url("../../assets/backgrounds/Tech-header-mobile.png");
    background-position: center bottom;
  }

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    max-width: 37.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
      width: 90%;
      margin: 2rem 0
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 2.5rem 0;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      line-height: 1.625rem;
      width: 90%;
      margin: 0;
    }
  }
}

.technical-title {
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
  max-width: 65.625rem;
  margin: 5rem auto;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.technical-package-container {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem auto 6.25rem;
  max-width: 78.125rem;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  .technical-package {
    box-sizing: border-box;
    border: 0.125rem solid $black;
    border-radius: 1rem;
    padding: 5.625rem;
    width: 48%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      margin-bottom: 1.25rem;
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 1.25rem;
    }

    .technical-package-subtitle {
      font-size: 1.5rem;
      line-height: 2rem;

      @media (max-width: 480px) {
        font-size: 1.25rem;
        line-height: 1.625rem;
      }
    }
    .content-wrapper {
      div.img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .technical-link-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.5rem;
      }
    }
    .content-wrapper-last-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      @media (max-width: 1024px) {
        display: block;
      }
      .content {
        max-width: 45%;
        @media (max-width: 1024px) {
          max-width: 100%;
        }
      }
      .img-container {
        align-items: flex-start !important;
        width: 35%;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      img {
        margin: 0 auto;
        height: auto;
        width: 100%;
        @media (max-width: 1024px) {
          height: 16rem;
          width: auto;
        }
        @media (max-width: 480px) {
          margin: 0.625rem 0;
        }
      }
    }
    h3 {
      font-size: 2rem;
      line-height: 2.5rem;

      @media (max-width: 480px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.375rem;
      margin: 0.625rem 0;
    }

    img {
      margin: 0 auto;
      height: 16rem;
      width: auto;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      @media (max-width: 480px) {
        margin: 0.625rem 0;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li::before {
        background-image: url("../../assets/Icons/greenCheck.png");
        background-repeat: no-repeat;
        min-width: 1rem;
        height: 1.25rem;
        content: "";
        position: relative;
        top: 0.125rem;
      }

      li {
        display: flex;
        margin: 1.25rem 0;
        font-size: 1rem;
        line-height: 1.375rem;

        b {
          font-family: "WalsheimBold";
          font-weight: 500;
          max-width: 31.25rem;
          margin-left: 1rem;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        span {
          width: 100%;
          margin-left: 0.375rem;
        }
      }
    }

    a {
      font-family: "WalsheimBold";
    }
    &:last-child {
      width: 100%;
      margin-top: 1rem;
    }
  }

}

.technical-info-container {
  display: flex;
  justify-content: space-evenly;
  padding: 5rem 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1.25rem;

    @media (max-width: 768px) {
      margin: 1.25rem auto;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 37.5rem;
    margin: 1.25rem;

    @media (max-width: 768px) {
      max-width: unset;
      margin: 0 auto;
    }
  }
}

