@import "utils/variables";

.job-carousel-container {
  margin: 8.125rem 0 6.25rem;
  padding: 2.5rem 0.625rem;
  background: $light_green;
  overflow: hidden;

  @media (max-width: 64rem) {
    padding: 2.5rem 0;
  }

  > h1 {
    text-align: center;
    font-size: 3rem;
    line-height: 3.5rem;
    padding-bottom: 0.5rem;
  }

  > p {
    text-align: center;
    font-size: 1rem;
    line-height: 2rem;
    padding: 0 0 1.25rem;
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }

  .slick-prev {
    z-index: 1;
    left: 0;
    top: 7.5rem;
    opacity: 0;
    transition: 0.5s;

    &::before {
      display: none;
    }
  }

  .slick-next {
    z-index: 1;
    right: 2rem;
    top: 7.5rem;
    opacity: 0;
    transition: 0.5s;

    &::before {
      display: none;
    }
  }

  .job-carousel-card {
    background: white;
    border-radius: 1.25rem;
    padding: 0.625rem;
    margin: 0.625rem;
    box-sizing: border-box;
    height: 17.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.1);

    &:hover {
      cursor: pointer;
    }

    .job-carousel-card-department {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    .job-carousel-card-title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 0.375rem 0 3.125rem;
    }

    .job-carousel-card-location {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
}
