@import "utils/variables";

.carousel-container {
  width: 100%;
  margin: 1.25rem auto 0;

  .slick-prev {
    z-index: 1;
    left: -0.625rem;
    top: 38%;

    &::before {
      display: none;
    }
  }

  .slick-next {
    z-index: 1;
    right: 1rem;
    top: 40%;

    &::before {
      display: none;
    }
  }

  .slick-list {
    border-radius: 1.875rem;
    overflow: visible;
  }

  .home-banner {
    max-width: 90rem;
    background-repeat: no-repeat;
    background-position: left -0.5rem top -26rem;
    height: 37.5rem;
    margin: 0 auto;
    border-radius: 1rem;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;

    @media (min-width: 769px) and (max-width: 1024px) {
      background-position: left -2.5rem top -5rem;
      background-size: 165%;
    }

    @media (max-width: 48rem) {
      width: 96%;
      background-position: bottom -2.5rem right 60%;
      background-size: 160%;
      box-shadow: inset 0 19.625rem 2rem 0 rgba(250, 249, 246, 1);
    }

    &.black-friday-promo {
      background-position: bottom right;
      background-size: cover;
      height: unset;
      box-shadow: unset !important;

      @media (min-width: 1025px) and (max-width: 1200px) {
        background-size: cover;
        background-position: right -2.5rem bottom;
      }

      @media (max-width: 1024px) {
        background-image: none !important;
        background-color: #e4fcbc;
      }

      @media (max-width: 48rem) {
        width: 96%;
        box-shadow: inset 0 19.625rem 2rem 0 rgba(250, 249, 246, 1);
      }

      .mobile-promo-image {
        background: linear-gradient(#97cb00, #ccef6d);
        padding: 2rem;
        position: relative;
        height: 17.25rem;
        border-radius: 2rem;
        margin-bottom: 1.5rem;
        @media (min-width: 1025px) {
          display: none;
        }
        .left-image {
          position: absolute;
          max-width: 50%;
          min-width: 14.5rem;
          height: 100%;
          left: 0;
          top: 50px;
          z-index: 2;
          @media (min-width: 769px) and (max-width: 1024px) {
            left: 20%;
          }
          img {
            max-width: 14.5rem !important;
          }
        }
        .right-image {
          position: absolute;
          width: 50%;
          height: 75%;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: flex-end;
          z-index: 1;
          @media (min-width: 769px) and (max-width: 1024px) {
            right: 20%;
          }
          img {
            width: auto;
            height: 100%;
            min-width: 11.25rem;
          }
        }
      }

      .carousel-header-title {
        @media (min-width: 769px) and (max-width: 1024px) {
          max-width: unset !important;
        }

        p {
          @media (min-width: 1025px) {
            max-width: 20rem;
          }

          @media (min-width: 769px) and (max-width: 1024px) {
            font-size: 2.5rem !important;
            line-height: 3rem !important;
            max-width: unset;
          }

          @media (max-width: 48rem) {
            font-size: 2rem;
            line-height: 2.5rem;
            max-width: unset;
          }
        }
      }

      .carousel-header-subtitle {
        @media (min-width: 769px) and (max-width: 1024px) {
          max-width: unset !important;
        }
        padding: 1rem 0 !important;

        p {
          line-height: normal !important;
          @media (min-width: 1025px) {
            max-width: 20rem;
          }

          @media (min-width: 769px) and (max-width: 1024px) {
            max-width: unset;
          }

          @media (max-width: 48rem) {
            max-width: unset;
          }
        }
      }


      .slide-container {
        height: unset;
        @media (min-width: 769px) and (max-width: 1024px) {
          padding: 2rem 1rem 1rem !important;
        }
      }

      .offset-border {
        border-radius: 2rem;
        width: 99%;
        height: 99%;
        position: absolute;
        bottom: -5px;
        right: -5px;
        z-index: 1;
        border: 2px solid #000;
      }
    }

    .asterisk-text {
      margin-top: 2rem;
      p {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: normal;
        max-width: 25rem;

        @media (min-width: 1025px) and (max-width: 1200px) {
          max-width: 18rem;
        }
      }
    }


    .slide-container {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;
      height: 38rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 5rem 2.5rem 2.5rem;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimizeQuality;

      @media (min-width: 769px) and (max-width: 1024px) {
        padding: 6.5rem 2.5rem 2.5rem;
      }

      @media (max-width: 48rem) {
        padding: 2rem 1rem 1rem;
        margin: 0.5rem;
      }

      .carousel-header-title {
        font-size: 3rem;
        line-height: 3.5rem;
        font-family: "WalsheimBold", serif;
        box-sizing: border-box;
        max-width: 32rem;

        @media (min-width: 769px) and (max-width: 1024px) {
          font-size: 2.5rem;
          line-height: 3rem;
          max-width: 20.5rem;
        }

        @media (max-width: 48rem) {
          font-size: 2rem;
          line-height: 2.5rem;
          max-width: 100%;
        }

        p {
          font-size: 3rem;
          line-height: 3.5rem;
          font-family: "WalsheimBold", serif;
          box-sizing: border-box;

          @media (min-width: 769px) and (max-width: 1024px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }

          @media (max-width: 48rem) {
            font-size: 2rem;
            line-height: 2.5rem;
          }
        }
      }

      .carousel-header-subtitle {
        font-size: 1.5rem;
        line-height: 2rem;
        padding: 2rem 0 1.5rem;
        box-sizing: border-box;
        max-width: 25rem;

        @media (min-width: 769px) and (max-width: 1024px) {
          font-size: 1.25rem;
          line-height: 1.75rem;
          padding: 1.25rem 0 1.25rem;
          max-width: 18rem;
        }

        @media (max-width: 48rem) {
          font-size: 1.25rem;
          line-height: 1.625rem;
          max-width: 100%;
        }

        p {
          margin: 0.375rem 0;
          line-height: 2rem;
        }
      }

      .carousel-header-error-container {
        position: relative;
        margin: 0 0 1rem;

        input {
          border-radius: 3.125rem;
          border: 1px solid $black;
          font-size: 1rem;
          padding: 0.75rem 1rem;
        }

        input.error {
          border: 1px solid $error;
        }

        .carousel-header-address-input {
          width: 22rem;
          margin-right: 0.625rem;

          @media (min-width: 769px) and (max-width: 1024px) {
            width: 18rem;
          }

          @media (max-width: 48rem) {
            width: 13rem;
          }
        }

        .carousel-header-unit-input {
          width: 3.5rem;

          @media (max-width: 48rem) {
            width: 3.125rem;
          }
        }

        .carousel-header-error-text {
          position: absolute;
          top: 3.25rem;
          left: 1.125rem;
          font-size: 0.75rem;
          line-height: 1rem;
          color: $error;
        }
      }

      button {
        width: 16rem;
        margin-top: 1rem;
      }

      .carousel-header-input-container {
        display: flex;
        position: relative;

        .carousel-header-dropdown {
          position: absolute;
          top: 3rem;
          left: 1.25rem;
          background: $white;
          border-radius: 0 0 1rem 1rem;
          width: 21.5rem;

          @media (max-width: 48rem) {
            width: 13.25rem;
          }

          p {
            padding: 0.5rem;

            &:last-child {
              border-radius: 0 0 1rem 1rem;
            }

            &:hover, &:focus {
              background: $hover_green;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
