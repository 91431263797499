@import "utils/variables";

.footer-container {
  margin-top: 1.25rem;
  .green-line {
    border-top: #99cc00 solid 1px;
    @media (max-width: 768px){
      margin: 0 0.5rem 2.5rem;
    }
  }

  .footer-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      margin: 0 0.5rem;
    }

    .footer-logo-container {
      margin-bottom: 1rem;
    }

    .footer-logo-container:hover {
      cursor: pointer;
    }

    .footer-links-container {
      display: flex;
      justify-content: space-between;
      width: 80%;

      @media (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }

      .footer-links-item {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        margin-bottom: 2rem;
        a {
          cursor: pointer;
          color: black;
        }
      }

      h2 {
        font-weight: 700;
        font-size: 1.2rem;
        margin-bottom: 1.25rem;
      }

      p {
        font-weight: 400;
        font-size: 1.1rem;
        margin-bottom: 1.25rem;
      }
    }
  }

  .footer-copyright-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 768px) {
      margin: 0 0.5rem;
    }

    .footer-border-top {
      display: none;

      @media (max-width: 1024px) {
        display: block;
      }
    }

    p {
      margin-top: 3.125rem;
      margin-bottom: 0;

      @media (max-width: 1024px) {
        margin-top: 0;
      }
    }

    .footer-social-container {
      margin-top: 0.5rem;

      .social-icon {
        margin-right: 1.25rem;

        @media (max-width: 1024px) {
          margin-left: 0;
          margin-right: 1.25rem;
        }
      }
    }
  }

  .footer-border-bottom {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
