@import "utils/variables";

.download-link-container {
  border-bottom: 1px solid $input_border;
  padding-bottom: 10px;
  width: 800px;
  margin: 20px auto;

  @media (max-width: 800px) {
    width: 90%;
  }

  a {
    margin-left: 10px;
    color: $secondary_forest;
  }
}
