@import "utils/variables";

.bblog-carousel-container {
  @media (max-width: 768px) {
    width: 100%;
    margin: 1.25rem 0 3rem
  }

  .blog-carousel-card {
    margin: 0 2%;
    border-radius: 1.375rem;

    img {
      width: 100%;
      height: 15rem;
      border-radius: 1.375rem 1.375rem 0 0;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimizeQuality;
    }

    .blog-carousel-content-container {
      padding: 2rem 2rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0 0 1.375rem 1.375rem;

      h2 {
        font-size: 1rem;
        line-height: 1;
        height: 9rem;
        font-weight: normal;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.375rem;
        }
      }

      .bcarousel-btn {
        width: 100%;
        margin: 3rem 0 1.5rem;

        a {
         color: black;
         padding: 0.5rem 1.5rem;
         border-radius: 1.375rem;
         border: 2px black solid;
        }
      }
    }
    .slick-slide {
      max-width: 30%;
      margin: 0 1%;

      @media (max-width: 1024px) {
        margin: 0;
        max-width: 100%;
      }

      div:first-child {
        margin: auto;
        display: flex;
      }

      div {
        margin: 0 auto;
      }
    }

    .slick-track {
      display: flex;
      justify-content: space-between;
    }
  }

  .slick-dots li button:before {
    font-size: 0;
    border: 0.125rem solid $btn_black;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: $white;
    top: 0.75rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background-color: $btn_black;
    opacity: 1;
  }

  .page-link-container div {
    margin-left: 0.125rem !important;
  }
}
