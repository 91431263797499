@import "utils/variables";

.start-background {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/backgrounds/bg_start.png");
  overflow: auto;

  .start-dropdown-container {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1024px) {
      margin-right: 1rem;
    }
  }

  .start-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .start-text {
      font-weight: 500;
      width: 50rem;
      text-align: center;
      line-height: 2.5rem;
      font-size: 2rem;
      margin: 3.125rem 0;

      @media (max-width: 1024px) {
        width: 90%;
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }
    .locations-text {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      font-weight: 700;
      text-align: center;

      @media (max-width: 480px) {
        margin: 4rem 0 1.5rem;
      }
    }

    .start-link-container {
      display: flex;
      justify-content: space-between;
      margin: 4rem 0;

      @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
      }

      .start-button-text {
        color: $secondary_forest;
        background: $white;
        border: 0.125rem solid $secondary_forest;
        padding: 0.75rem 1.875rem;
        border-radius: 3rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 2rem;

        p {
          font-weight: 700;
        }

        @media (max-width: 768px) {
          text-align: center;
          margin: 0 2rem 1rem;
          justify-content: center;
        }

        svg {
          padding-top: 1rem;
          vertical-align: middle;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .start-locations {
      display: flex;
      flex-direction: column;
      font-size: 1.25rem;
      line-height: 1.75rem;
      justify-content: center;
      align-items: flex-start;
      color: $secondary_forest;

      span {
        margin: 0 0.5rem;
      }
    }
  }
}
