@import "utils/variables";

.home-internet-box-item {
  border-radius: 1rem;
  border: 2px black solid;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 17rem;
  min-width: 15.625rem;
  position: relative;
  background-color: #fff;
  @media (max-width: 1024px) {
    max-width: 90%;
    padding: 2rem 1rem;
    width: 100%;
    min-width: unset;
  }
  @media (min-width: 1025px) and (max-width: 1300px) {
    margin: 1rem auto;
  }
  &.black-friday-promo {
    @media (max-width: 1024px) {
      margin-bottom: 6rem;
    }

  }
  .title-box {
    span {
    // Fibre Internet
      color: #325A32;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
      word-wrap: break-word
    }
    .speed  {
      color: black;
      font-size: 4rem;
      font-weight: 700;
      line-height: 4.5rem;
      word-wrap: break-word
    }
  }
  .blurb {
    margin: 1rem auto;
  }
  .features {
    p {
      display: flex;
      column-gap: 0.5rem;
      line-height: 2.5rem;
      span {
        line-height: 0;
        &.feature-point {
          display: flex;
          align-items: center;
          line-height: 1.25rem;
        }
      }
    }
    sup{
      cursor: pointer;
    }
    div.tooltip_content p {
      line-height: 1.5rem;
      margin-bottom: 0;
    }
  }
  .price-container {
    display: flex;
    flex-direction: column;
    .price-values {
      display: flex;
      margin: 1rem 0 1.5rem;
      justify-content: space-between;
      .regular-price {
        display: flex;
      }
      .number-dollar {
        color: $secondary_forest;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0.5rem;
        &.black-friday-promo {
          color: #6E6A67;
        }
      }

      .number-value {
        color: $secondary_forest;
        font-size: 4.5rem;
        line-height: 4.75rem;
        font-weight: 700;
        &.black-friday-promo {
          color: #6E6A67;
          text-decoration-line: line-through;
          -webkit-text-decoration-line: line-through;
          text-decoration-color: #000;
          -webkit-text-decoration-color: #000;
          text-decoration-thickness: 4px;
          -webkit-text-decoration-thickness: 4px;
          padding-right: 1rem;
        }
      }

      .number-text {
        font-size: 1.25rem;
        position: relative;
      }

      .number-tax {
        font-size: 0.75rem;
      }

      .extra-info {
        height: 4.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .promo-number-text {
          color: $autopay_grey;
          font-size: 1.5rem;
          font-weight: 700;
          word-wrap: break-word;
        }
        .number-tax{
          cursor: pointer;
        }
      }
    }
    .credit-text {
      position: relative;
      top: -1.25rem;
      color: $autopay_grey;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;
      word-wrap: break-word
    }
  }
  .black-friday-image-offset {
    position: absolute;
    bottom: 1.5rem;
    right: -6.125rem;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .black-friday-image-offset-mobile {
    position: absolute;
    border-radius: 0 0 1.25rem 1.25rem;
    bottom: -105px;
    left: 0;
    background: linear-gradient(0deg, #C9ED66 -14.87%, #9C0 62.63%);
    padding: 2rem 0 0;
    height: 5.25rem;
    width: 100%;
    text-align: center;
    z-index: -1;
    @media (min-width: 1201px) {
      display: none;
    }
    .offset-border {
      border-radius: 1.25rem;
      width: 99%;
      height: 83%;
      position: absolute;
      bottom: -2px;
      right: -3px;
      z-index: 1;
      border: 1px solid #000;
    }
  }
  .availability-btn {
   font-size: 1rem;
   font-weight: 700;
   line-height: 1.5rem;
  }
}
