@import "utils/variables.scss";

.radioBtnStart_container {
  margin-top: 0.25rem;

  .radio-content-container {
    margin: 0.625rem 0;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        padding-right: 1rem;
      }
    }

    input[type="radio"] {
      clip: rect(0, 0, 0, 0);
      position: absolute !important;
      white-space: nowrap;
    }

    input[type="radio"]:checked ~ label svg.radio-unchecked,
    input[type="radio"] ~ label svg.radio-checked,
    {
      display: none;
    }

    input[type="radio"]:checked ~ label {
      font-weight: 700;

      & svg.radio-checked {
        display: block;
        path {
          stroke: #325a32;
          stroke-width: 1px;
        }
      }
    }
    input[type="radio"] ~ label svg.radio-unchecked {
      display: block;
    }
  }
}
