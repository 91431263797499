@import "utils/variables.scss";

.go-card {
  background: $white;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.1);
  max-width: 22rem;
  width: 30%;
  min-height: 27.5rem;
  margin: 1.25rem auto;
  display: flex;
  flex-direction: column;
  padding-top: 0.625rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  .go-card-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 11rem;

    p {
      color: $secondary_forest;
      font-size: 1rem;
      line-height: 1.5rem;
      border: 0.125rem solid $secondary_forest;
      padding: 0.625rem 1.5rem;
      border-radius: 1.375rem;
      margin: 1.25rem 0 1.875rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .go-card-list-container {
    border-top: 1px solid black;
    padding: 1.25rem;
    box-sizing: border-box;

    h2 {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: 0.625rem;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      max-height: 50%;

      p {
        padding: 0.375rem 0;
        font-size: 1rem;
        line-height: 1.375rem;
        width: 50%;
      }
    }
  }
}
