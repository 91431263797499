@import "utils/variables";

.orderPage_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  // padding: 0 5rem;

  .orderPage_contact_container {
    display: flex;
    flex-direction: column;
    background-color: rgba(237, 237, 237, 0.15);
    padding: 2.25rem 1rem;
    text-align: center;

    p {
      max-width: 25rem;
      align-self: center;
    }
  }

  .orderPage_content_wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 1.25rem 0rem;
    max-width: 67.5rem;
    margin-bottom: 1.25rem;
    width: 100%;

    .orderPage_logo {
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 78.375rem) {
      padding: 1rem 1.25rem;
      box-sizing: border-box;
    }
    @media screen and (max-width: 48rem) {
      // width: auto;
    }

    h1 {
      margin-top: 3rem;

      @media screen and (max-width: 48rem) {
        margin-top: 1.5rem;
        font-size: 24px;
      }
    }

    //MAIN CONTAINER FOR ORDER PAGE
    .orderPage_main_container {
      display: flex;
      padding: 6rem 0 2rem 0;

      @media (max-width: 78.375rem) {
        flex-direction: column;
        padding: 3.5rem 0 0 0;
      }

      @media screen and (max-width: 48rem) {
        padding: 1.5rem 0 0 0;
      }


      // RIGHT BLOCK
      .orderPage_right_block_container {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        flex: 0.3;
        padding: 2rem 1.25rem;
        margin-left: 1rem;
        border-radius: 1rem;
        background-color: $light_green;
        position: sticky;
        top: 0;

        @media (max-width: 78.375rem) {
          display: flex;
          flex: 1;
          width: 100%;
          margin: 2.5rem 0 0 0;
          padding: 0;
        }
      }
    }
  }
}

.orderPage_sticky_footer_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: #eafcc3;
  position: sticky;
  bottom: 0;
  z-index: 10000;
  max-height: 80vh;

  .sticky_footer_caret {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .sticky_footer_total {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
}
