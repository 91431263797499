@import "utils/variables";

.tooltip_content {
  position: absolute;
  width: 21.5rem;
  padding: 1.5625rem;
  background-color: $tooltip_background;
  top: 0.5rem;
  left: 2.5rem;
  max-width: 25rem;
  z-index: 100;
  border-radius: 24px;
  font-family: "WalsheimRegular";
  font-weight: 400;

  @media (max-width: 480px) {
    max-width: 16rem;
  }

  p {
    width: 100% !important;
    margin-left: 0 !important;
    font-size: 1rem;

    &:nth-child(1) {
      margin-bottom: 1rem;
    }
    a {
      color: $secondary_forest;
      text-decoration: underline;
    }
  }
}

.tooltip_helper_element {
  position: absolute;
  top: 0.625rem;
  left: 1.25rem;
  width: 1.875rem;
  height: 2.5rem;
  background-color: transparent;
}

.accordion_info_icon {
  margin-top: 0.55rem;
  margin-left: 0.25rem;

  &:hover {
    // cursor: pointer;
  }
}
