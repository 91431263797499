@import "utils/variables.scss";

.checkbox_container {
  display: flex;
  width: 1.15em;
  height: 1.15em;
  position: relative;

  input[type="checkbox"] {
    appearance: none;
    font: inherit;
    color: $secondary_forest;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid $secondary_forest;
    border-radius: 0.15em;
    z-index: 10;
  }

  .checkmark {
    position: absolute;
  }

  input:disabled ~ .checkmark svg path {
    fill: #acacac;
  }
}
