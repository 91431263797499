@import "utils/variables.scss";

.calendar_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-wrap: break-word;
  border: 2px solid #4b4a4a;
  border-radius: 1.375rem;
  margin-top: 1.5625rem;
  height: 26.5625rem;

  @media screen and (max-width: 48rem) {
    flex: 1;
    height: 19.5rem;
    margin-left: 0.75rem;
  }

  // HEADER
  .calendar_header_container {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.9375rem;
    align-items: center;
    height: 4.6875rem;
    background-color: #dfefb0;

    h2 {
      text-transform: capitalize;
    }

    @media screen and (max-width: 48rem) {
      height: 3rem;
    }
    .caret {
      &:hover {
        cursor: pointer;
      }
    }

    .calendar-navigation {
      display: flex;
      column-gap: 10px;
      cursor: pointer;
    }
  }

  // MONTHS
  .month {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    font-family: "WalsheimRegular";
    position: relative;
    height: 3.625rem;
    @media screen and (max-width: 48rem) {
      height: 2.75rem;
    }

    .tooltip {
      position: absolute;
      top: -2rem;
      left: -1.25rem;
      width: max-content;
      background-color: rgb(236, 236, 236);
      color: #505050;
      font-size: 0.75rem;
      border-radius: 1rem;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      z-index: 1000;
    }

    &:hover {
      cursor: pointer;
      background-color: $calendar_hover_green;
    }
  }
  .fully-booked {
    line-height: 1;
    font-size: 0.75rem;
    text-align: center;
  }
  .today_dot {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0.1rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      margin-left: -0.2rem;
      background-color: $background_selected;
    }

    @media screen and (max-width: 48rem) {
      &::after {
        content: "";
        position: absolute;
        top: 0.09rem;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: $background_selected;
      }
    }
  }
  .strikethrough {
    position: relative;
    width: 1.5rem;
    text-align: center;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    width: 1.5rem;
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;

    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -ms-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }
  .weekDay_container {
    display: flex;
    justify-content: center;
    height: 3.125rem;

    @media screen and (max-width: 48rem) {
      height: 2.125rem;
    }

    .weekDay {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
      color: #4b4a4a;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
