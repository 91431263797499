@import "utils/variables";

//  YOUR INFO
.stepThree_yourInfo_form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex: 1;
  border: 0.125rem solid black;
  border-radius: 1.375rem;
  padding: 1.875rem 2.1875rem;

  .form_sub_title {
    margin-bottom: 0.5rem;
    padding: 0rem 0rem 1.375rem 0rem;

    @media screen and (max-width: 48rem) {
      padding: 0rem 0.93rem 0.5rem 0.93rem;
    }

    p {
      font-family: "WalsheimRegular";
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1;

    div {
      display: flex;
      flex-direction: column;
      width: 45%;

      @media screen and (max-width: 48rem) {
        width: 100%;
      }

      label {
        font-size: 12px;
        color: $btn_dark;
      }

      input {
        border-width: 1px;
        border-color: $input_border;
        border-radius: 8px;
        padding: 8px;
        box-shadow: none;
        border-style: solid;
      }
    }

    .edit_btn {
      position: absolute;
      top: 31px;
      right: 9.5px;
      padding-left: 5px;
      color: #325a32;
      text-decoration: underline;
      cursor: pointer;
      border: none;
    }
  }

  @media screen and (max-width: 48rem) {
    padding: 0;
    form {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      flex: 1;
      padding: 0rem 0.93rem 2rem 0.93rem;
    }
  }

  .form_title {
    width: 100%;

    @media screen and (max-width: 48rem) {
      width: 100%;
      padding: 1rem 1rem 1rem 1rem;
      border-bottom: 2px solid black;
    }
  }
}
