@import "utils/variables";

.preorder-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.75rem 5rem 1.875rem;
  height: fit-content;
  max-width: 38.75rem;
  position: relative;

  @media (max-width: 480px) {
    margin: 1.25rem 0.625rem;
    padding: 1.25rem 1rem 1.875rem;
    overflow: scroll;
    max-height: 84vh;
  }
  .preorder-container-header {
    h2 {
      font-size: 1.625rem;
      line-height: 2rem;
      text-align: center;
    }
  }
  .preorder-container-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    .preorder-container-address-dropdown {
      width: 100%;
      margin-top: 1rem;

      .preorder-container-address-dropdown-select {
        width: 100%;
        height: 3.125rem;
        border-radius: 0.625rem;
        background-size: 0.75rem;
        outline: none;

        &:hover {
          cursor: pointer;
        }

        & > option {
          font-size: 1rem;
        }
      }
    }
  }
  .address-info {
    margin: 1rem 0;
    max-width: 28rem;
    text-align: center;
    strong {
      white-space: nowrap;
    }
  }
  .extra-services-info {
    margin: 1rem 0 0;
    text-align: center;
    max-width: 30rem;
  }
  .packages-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 28.5rem;
    @media (max-width: 768px) {
      min-width: unset;
      flex-direction: column;
    }
    &.black-friday-promo {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 20rem;
      &.gpon {
        .package-box-container:first-child {
          width: 100%;
        }
      }
      &.xgspon {
        .package-box-container:last-child {
          width: 100%;
        }
        .package-box.black-friday-promo {
          min-height: 15rem;
          @media (max-width: 400px) {
            min-height: 13rem;
          }
        }
      }
    }
    .package-box {
      border-radius: 1rem;
      @media (max-width: 768px) {
        margin: 0.5rem 0;
      }
      &.black-friday-promo {
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 19rem;
        padding: 0.5rem 0 0 0;
      }
      .box-header {
        padding: 0.625rem 0.5rem 0;
        border-radius: 1rem 1rem 0 0;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.375rem;
        color: #325A32;
        background: rgba(234, 252, 195, 0.50);
        text-align: center;
        &.black-friday-promo {
          margin: 0 0.75rem 0 0.65rem;
          background-color: #F4FDE2;
        }
        &.coming-soon {
          padding: 0.25rem 0.5rem 0.25rem;
          letter-spacing: -0.05rem;
          font-weight: 500;
          background: $btn_disabled;
          color: $black;
        }
      }
      .box-body {
        background: rgba(234, 252, 195, 0.50);
        padding: 0.625rem 1rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        border-radius: 0 0 1rem 1rem;
        &.black-friday-promo {
          margin: 0 0.75rem 0 0.65rem;
          background-color: #F4FDE2;
        }
        &.coming-soon {
          background: rgba(172, 172, 172, 0.30);
          color: $btn_disabled;
        }
      }
    }
  }

  .preorder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0;

    @media screen and (max-width: 480px) {
      margin: 2.5rem 0 0;
    }

    button {
      padding: 0.5rem 3rem;
    }

    .preorder-modal-text {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
      margin-top: 2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .preorder-container-footer {
    margin-top: 2rem;
  }
  .switch-region-container {
    display: flex;
    color: $warning;
    background-color: $warning_background;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    margin: 0 0 1rem;
    align-items: center;
    justify-content: space-between;

    .left-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      svg path {
        fill: $warning;
      }
    }
    .right-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        margin-left: 1.5rem;
      }

      span {
        color: $secondary_forest;
        min-width: 200px;
        &:hover {
          cursor: pointer;
        }
        text-align: right;
      }
    }

    @media screen and (max-width: 48rem) {
      padding: 1.5rem 2rem;

      .left-content {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 0;
      }
      .right-content {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        p {
          margin-left: 0;
          width: 100%;
        }
        span {
          margin-top: 0.35rem;
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .black-friday-promo {
    text-align: center;
  }
}
.promo-container {
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url('/assets/backgrounds/bg_bf_promo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 6.375rem;
  border-radius: 2rem;
  margin: 0 auto 1rem;
  z-index: 0;
  width: 98%;
  bottom: 0;
  @media (max-width: 768px) {
    background-image: url('/assets/backgrounds/bg_left_bf_mobile.png'), url('/assets/backgrounds/bg_bf_promo.png');
    background-position: left 88%, center;
    background-size: auto, cover;
  }
  .promo-image-container {
    position: absolute;
    bottom: -6px;
    z-index: 2;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      bottom: unset;
    }
    img {
      max-height: 8rem;
    }
  }

  .offset-border {
    border-radius: 2rem;
    width: 99.8%;
    height: 99.8%;
    position: absolute;
    bottom: -3px;
    right: -2px;
    z-index: 1;
    border: 1px solid #000;
  }
}
