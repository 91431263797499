@import "utils/variables";

.career-header {
  display: flex;
  justify-content: space-between;
  margin: 7.75rem 2.25rem 5rem;

  @media (max-width: 64rem) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 7.75rem 0 2.5rem;
  }

  .career-header-content {
    max-width: 31.25rem;
    margin-right: 2rem;

    @media (min-width: 64.0625rem) and (max-width: 75rem) {
      max-width: 26.25rem;
    }

    @media (max-width: 64rem) {
      max-width: 100%;
      margin-top: 2.25rem;
      margin-right: 0;
      width: 100%;
    }

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      margin-bottom: 1.5rem;

      @media (min-width: 64.0625rem) and (max-width: 75rem) {
        font-size: 2.5rem;
        line-height: 3rem;
      }

      @media (max-width: 64rem) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: 2rem;

      @media  (max-width: 75rem) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    button {
      width: 15.625rem;
    }
  }

  .career-header-video {
    width: 100%;
  }
}

.career-marquee-header {
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 8.125rem 0 1.25rem;

  @media (min-width: 48.0625rem) and (max-width: 64rem) {
    margin: 5rem 0.625rem 1.25rem;
  }

  @media (max-width: 48rem) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 5rem 0.625rem 1.25rem;
  }
}

.career-package-header {
  text-align: center;
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 5rem 0;

  @media (max-width: 48rem) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.career-badge-container {
  display: flex;
  margin: 5rem auto;
  flex-wrap: wrap;

  @media (max-width: 64rem) {
    display: flex;
    flex-direction: column;
    margin: 5rem auto 2rem;
  }
}

.career-life-container {
  display: flex;
  justify-content: space-between;
  margin: 5rem 0 5rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    margin: 2rem 0 2rem;
  }

  .career-life-content {
    margin: 0 2rem 0;
    max-width: 24rem;

    @media (max-width: 64rem) {
      margin: 0;
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 2rem;

      @media (max-width: 64rem) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 64rem) {
      margin-bottom: 20px;
    }
  }
}

.career-learn-container {
  background: $light_purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.75rem;
  margin: 3.75rem 0;

  @media (max-width: 48rem) {
    padding: 3.75rem 0.625rem;
  }

  p {
    font-size: 3rem;
    line-height: 3.5rem;
    text-align: center;
    width: 56.25rem;
    padding-bottom: 2.5rem;

    @media (max-width: 48rem) {
      width: 100%;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

.career-review-container {
  overflow: hidden;
  margin-bottom: 5rem;

  h2 {
    text-align: center;
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 2.5rem 0 2.5rem;

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 2.5rem
    }
  }
}
