@import "utils/variables";

.homepage-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 5rem auto;

  @media (max-width: 768px) {
    margin: 5rem 0 0;
    width: 100%;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: center;
    margin: 0 1.25rem;
  }

  .orderPage {
    flex-direction: row !important;

    @media (max-width: 768px) {
      flex-direction: row !important;
    }
  }

  .homepage-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 3.75rem;
      align-items: center;
    }

    .homepage-contact-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
      width: 20%;

      @media (max-width: 768px) {
        margin: 0;
        width: 100%;
      }

      p {
        min-height: 0.8rem;
        width: 100%;
      }

      a {
        color: #325a32;
      }

      a:visited {
        color: #11774f;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h4 {
        margin-top: 1.25rem;
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
        font-weight: 500;
      }

      b {
        font-family: "WalsheimBold", serif;
        font-weight: 200;
      }

      .contactUsButton {
        margin-top: 1.5rem;

        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1.5rem;
        gap: 0.625rem;

        /* UI/Secondary-Forest */
        border: 0.125rem solid #325a32;
        border-radius: 22px;

        color: #325a32;
        font-weight: 700;
      }

      @media (max-width: 768px) {
        margin-bottom: 3.75rem;
      }
    }
  }
}
