@import "utils/variables";

.support-container {
  margin-bottom: 2.5rem;

  .support-header {
    background-image: url("../../../assets/backgrounds/gradient.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 25rem;
    width: 96%;
    border-radius: 1rem;
    margin: 0.625rem auto 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    box-sizing: border-box;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    div {
      position: relative;
      margin: 2.5rem 0;
      max-width: 90%;

      .support-search-icon {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
      }

      input {
        width: 37.5rem;
        border-radius: 1.875rem;
        border: none;
        padding: 0.625rem 1.875rem 0.625rem 1.875rem;
        font-size: 1rem;
        line-height: 1.375rem;

        @media (max-width: 768px) {
          width: 18.75rem;
        }

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }

  .support-item-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }

    .support-question-container {
      max-width: 30rem;
      margin: 1rem 3rem 4rem;

      @media (max-width: 1024px) {
        width: 100%;
        max-width: 35rem;
      }

      @media (max-width: 768px) {
        width: 80%;
      }

      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0.625rem 0 1.25rem;
      }

      .support-question-text {
        display: flex;
        width: 100%;
        padding: 0 0 0.625rem 1.25rem;
        position: relative;
        margin: 0.625rem 0.625rem 0.625rem -1.25rem;

        &:hover {
          cursor: pointer;
        }
      }

      .support-question-text[aria-border="true"] {
        border-bottom: 1px solid #eaecf0;
      }

      .support-carret-container {
        position: absolute;
        left: -0.625rem;
      }
    }
  }

  .support-search-bar-container {
    display: flex;
    align-items: center;
    justify-content: stretch;

    @media (max-width: 480px) {
      width: 100%;
    }

    .support-dropdown {
      position: absolute;
      top: 0.125rem;
      left: 1.125rem;
      background: $white;
      border-radius: 0 0 1rem 1rem;
      width: 94%;
      z-index: 100;

      @media (min-width: 30.0625rem) and (max-width: 48rem) {
        width: 90%;
      }
      @media (max-width: 30rem) {
        width: 85%;
      }

      p {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        text-align: left;

        &:last-child {
          border-radius: 0 0 1rem 1rem;
        }

        &:hover, &:focus {
          background: $hover_green;
          cursor: pointer;
        }
      }
    }
  }
}
