@import "utils/variables.scss";

.verbatim-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .question {
    display: flex;
    column-gap: 0.5rem;
    font-weight: bold;
  }
  textarea {
    resize: none;
    padding: 1rem;
  }
}
.qnormal > .question {
  font-weight: normal !important;
}