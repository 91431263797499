@import "utils/variables";

.accordion_container {
  display: flex;
  flex-direction: column;
  border: 2px solid $black;
  border-radius: 1rem;

  .accordion_devider_line_title {
    border-bottom: 2px solid $black;
    width: 100%;
  }

  // TITLE STYLING
  .accordion_title_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 0.5rem;

    .accordion_left_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      .accordion_checkbox {
        display: flex;
        flex-direction: row;
        position: relative;
        flex: 1;

        h2 {
          margin-top: 0.1875rem;
        }

        .accordion_checkbox_element {
          margin: 0.3rem 0 0 0.65rem;
        }

        .accordion_inner_title {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          margin-left: 0.6rem;
        }
      }
    }

    .accordion_right_title {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      &:hover {
        cursor: pointer;
      }
    }

    .accordion_info_icon {
      margin-top: 0.55rem;
      margin-bottom: 0.2rem;
      margin-left: 0.25rem;

      &:hover {
        // cursor: pointer;
      }
    }
  }

  //MAIN SECTION STYLING
  .accordion_content_wrapper {
    // margin: 0 0 0 3rem;
  }

  .accordion_alacarte_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .accordion_alacarte_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 2rem;
      border-radius: 1.5rem;
      background-color: #325a32;
      padding: 0 1rem;
      margin: 0 0rem 1.5rem 1rem;

      @media screen and (max-width: 48rem) {
        margin: 0 0rem 1.5rem 0.75rem;
      }

      .accordion_alacarte_item_text {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 0.75rem;
        margin-right: 0.5rem;
      }
      .accordion_alacarte_item_close {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
