@import "utils/variables";

.blog-carousel-container {
  margin: 1.25rem 0;
  width: 70%;

  @media (max-width: 1024px) {
    width: 65%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 1.25rem 0 3rem
  }

  .page-link-container div {
    margin-left: 0.125rem !important;
  }

  .slick-slide {
    max-width: 30%;
    margin: 0 1%;

    @media (max-width: 768px) {
      margin: 0;
      max-width: 100%;
    }

    div:first-child {
      margin: auto;
      display: flex;
    }

    div {
      margin: 0 auto;
    }
  }

  .slick-dots li button:before {
    font-size: 0;
    border: 0.125rem solid $btn_black;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: $white;
    top: 0.75rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background-color: $btn_black;
    opacity: 1;
  }

  .slick-disabled {
    cursor: default;
    svg rect {
      fill: #325a32;
      opacity: 50%;
    }
  }

  .slick-prev {
    z-index: 1;
    left: -3.125rem;
    top: 40%;

    &::before {
      display: none;
    }

    @media (max-width: 768px) {
      left: -0.75rem;
    }
  }

  .slick-next {
    z-index: 1;
    right: 1.875rem;
    top: 40%;

    &::before {
      display: none;
    }

    @media (max-width: 768px) {
      right: 1.5rem;
    }
  }

  .blog-carousel-card {
    max-width: 16.875rem;
    height: 17.5rem;
    margin: 0;
    border-radius: 1.375rem;
    background: white;

    img {
      width: 100%;
      height: 100%;
      max-height: 8.4375rem;
      border-top-left-radius: 1.375rem;
      border-top-right-radius: 1.375rem;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimizeQuality;
    }

    .blog-carousel-content-container {
      height: 7.25rem;
      margin: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
  }
}
