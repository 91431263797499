@import "utils/variables";

.logo-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;

  .title-text {
    margin: 1.75rem 0;

    @media (min-width: 769px) and (max-width: 1024px) {
      margin: 1.5rem 0;
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
    }

    h2 {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: $spinach_green;
      font-family: 'WalsheimBold', sans-serif;

      @media (max-width: 1024px) {
        font-size: 2rem;
        line-height: normal;
      }
    }
  }

  .logos-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 1.5rem;

    .logo {
      max-width: 15%;
      text-align: center;
      width: 100%;

      @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 30%;
        text-align: center;
        width: 100%;
      }

      @media (max-width: 768px) {
        max-width: 45%;
        text-align: center;
        width: 100%;
      }
    }
  }
}
