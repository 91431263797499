$black: #000;
$btn_black: #4b4a4a;
$btn_grey: rgba(197, 197, 197, 0.5);
$btn_disabled: #acacac;
$white: #fff;
$gray: #c7c7c7;
$background_gray: #ededed;
$dark_grey: #202020;
$btn_dark: #201f1f;
$btn_hover: #8cbe00;
$primary_green: #99cc00;
$secondary_forest: #325a32;
$spinach_green: #6D9100;
$tertiary_green: #009f6a;
$light_green: #e4fcbc;
$hover_green: #d0e88a;
$dark_green: #d8eddd;
$darker_green: #238202;
$calendar_hover_green: #dfefb0;
$order_promo_background: rgba(234, 252, 195, 0.5);
$light_purple: #f6f4fa;
$purple: #e0d9ec;
$dark_purple: #e8e3f1;
$blue: #e8f4eb;
$border_dark: #201f1f;
$error_background: #fae6e7;
$error: #cb060a;
$error_input_background: #fce6e6;
$background_selected: #bbdd54;
$input_border: #acacac;
$tooltip_background: #ededed;
$calendar_normal: #4b4a4a;
$calendar_inactive: #c5c5c5;
$yellow: #fffdeb;
$instagram_feed_background: #f6e8ed;
$warning: #201f1f;
$warning_background: rgba(255, 246, 148, 0.5);
$autopay_grey: #808080;
$background-blue: #f2f6f7;