@import "utils/variables";

.access-header {
  background-image: url("../../assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 25rem;
  border-radius: 1rem;
  margin: 0 auto 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    text-align: center;
    margin-bottom: 1.875rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 3.5rem;
      margin-bottom: 0.625rem;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 34.375rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.375rem;
      width: 21.875rem;
    }
  }
}

.access-container {
  width: 43.75rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 92%;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 3.75rem 0 1.25rem 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 1.25rem;

    span,
    a {
      color: $secondary_forest;
    }
  }

  .access-question-display {
    display: none;
  }

  .access-qustion-container {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    p {
      margin: 1.25rem 0 0.625rem;
    }

    textarea,
    input {
      border: 1px solid $black;
      border-radius: 0.5rem;
      padding: 0.625rem;
      box-sizing: border-box;
    }

    textarea {
      max-width: 100%;
      width: 100%;
    }

    input {
      width: 18.75rem;
    }

    input:nth-of-type(1) {
      margin-bottom: 1.25rem;
    }

    .access-error-text {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1rem;
      color: $error;
    }
  }

  .access-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
}
