@import "utils/variables";

@font-face {
  font-family: "WalsheimRegular";
  src: local("WalsheimRegular"),
    url(assets/font/GT_Walsheim/GT-Walsheim-Regular.woff) format("woff");
}
@font-face {
  font-family: "WalsheimBold";
  src: url(assets/font/GT_Walsheim/GT-Walsheim-Bold.woff) format("woff");
}

@font-face {
  font-family: "AghartiRegular";
  src: local("AghartiRegular"),
    url("assets/font/Agharti/Agharti-RegularUltraWide.ttf") format('truetype');
}

* {
  line-height: 22px;
  letter-spacing: 0.25px;
}

body {
  margin: 0;
  font-family: "WalsheimRegular", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p {
    margin: 0;
    font-family: "WalsheimRegular";
  }

  input,
  textarea {
    font-family: "WalsheimRegular", serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: "WalsheimBold", serif;
  }

  .Wbold {
    font-family: "WalsheimBold", serif;
  }

  .Wregular {
    font-family: "WalsheimRegular", serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
}

a {
  text-decoration: none;
  color: $secondary-forest;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
  color: #325a32;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input:focus, textarea:focus, input[type]:focus {
  outline: 0 none;
  box-shadow: 0 0 0 1px #000;
}

.resi-container {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 90%;
  }

  .resi-margin-container {
    margin: 0 3rem;

    @media (max-width: 1024px) {
      margin: 0;
    }

    @media (max-width: 480px) {
      margin: 0;
    }
  }
}

.is-tablet-only {
  display: none;

  @media (min-width: 769px) and (max-width: 1024px) {
    display: block !important;
  }
}

.is-hidden-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.is-hidden-tablet {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.is-hidden-tablet-only {
  @media (min-width: 769px) and (max-width: 1024px) {
    display: none !important;
  }
}

.is-hidden-desktop {
  @media (min-width: 1025px) {
    display: none !important;
  }
}

.nav-dropdown__option--is-selected {
  background: #99cc00 !important;
}

.fresnel-container {
  max-width: 90rem;
  background-repeat: no-repeat;
  background-position: left -0.5rem top -26rem;
  height: 37.5rem;
  margin: 0 auto;
  border-radius: 1rem;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  @media (min-width: 769px) and (max-width: 1024px) {
    background-position: left -2.5rem top -5rem;
    background-size: 165%;
  }

  @media (max-width: 48rem) {
    width: 96%;
    background-position: bottom -2.5rem right 60%;
    background-size: 160%;
    box-shadow: inset 0 17.625rem 2rem 0 rgba(255, 255, 255, 1);
  }
}