@import "utils/variables";

.list-badge-container {
  margin: 0 auto 3rem;
  width: 45%;

  @media (max-width: 64rem) {
    width: 100%;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;

    @media (max-width: 48rem) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 1.25rem 0;
  }

  ul {
    padding-left: 1.5rem;

    li {
      margin: 0.625rem 0;
    }
  }
}
