.header-wrapper {

  .header-flex {
    display: flex;
    margin: 1.5rem auto;
    @media (max-width: 768px) {
      display: none;
    }
    .logo {
      margin-right: 5rem;
      display: flex;
      align-items: center;
    }
    .links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      .main-nav {
        display: flex;
        position: relative;
        .nav-link {
          margin: auto 1rem;
          color: #201F1F;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.375rem;
          word-wrap: break-word
        }
      }
    }
  }
  .header-flex-mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
  }
}