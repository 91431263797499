@import "utils/variables";


.back-navbar {
  background: linear-gradient(180deg, #202020 0 38px, white 38px 100%);
}
.home-header-container {
  position: sticky;
  top: -2.375rem;
  z-index: 20;

  .is-relative {
    position: relative;
  }

  .header-top-bar {
    background: $dark_grey;
    display: flex;
    justify-content: space-between;
    z-index: 3;

    p {
      color: $white;
    }

    a {
      color: $white;
    }

    p:hover {
      cursor: pointer;
    }

    .header-type-select {
      display: flex;

      p {
        padding: 0.625rem 1.25rem 0;
        margin: 0;

        @media (max-width: 480px) {
          padding: 0.625rem 0.625rem 0;
        }
      }

      .header-residential {
        background: $white;
        color: $dark_grey;
        border-radius: 0.5rem 0.5rem 0 0;

        a {
          color: $dark_grey;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header-select-container {
      display: flex;
      align-items: center;

      .header-input-container {
        display: flex;
        align-items: center;
        margin-right: 2.5rem;
        width: 5rem;

        @media (max-width: 1024px) {
          margin-right: 0;
        }
      }

      .header-sign-in {
        @media (max-width: 768px) {
          display: none;
        }

        p {
          margin-left: 0.625rem;
        }
      }
    }
  }

  .header-bottom-bar {
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.375rem 1.25rem;
    z-index: 2;

    @media (max-width: 768px) {
      display: none;
    }

    .header-nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      max-width: 42rem;
      margin-top: 0.3rem;

      .header-nav-logo-container:hover {
        cursor: pointer;
      }

      .header-nav-item {
        a {
          color: $black;
        }
        &:hover {
          cursor: pointer;
        }
      }

      .header-hover-container {
        display: flex;
        align-items: center;
        height: 100%;

        &:hover .header-hover-dropdown,
        &:focus-within .header-hover-dropdown {
          display: inline-block;
        }

        .header-nav-item {
          margin-right: 10px;
          position: relative;
          height: 100%;
          p:focus-within + .header-hover-dropdown {
            opacity: 1;
            display: inline-block;
          }
        }

        img {
          height: 0.625rem;
        }
      }

      .header-hover-dropdown {
        background: $white;
        border-radius: 0 0 1rem 1rem;
        position: absolute;
        top: 1.25rem;
        left: -0.125rem;
        width: 12.5rem;
        display: none;
        //opacity: 0;

        &:focus-within, {
          //opacity: 1;
          display: inline-block;
        }

        a {
          &:last-child .hover-dropdown-item {
            border-radius: 0 0 1rem 1rem;
          }

          .hover-dropdown-item {
            padding: 1.25rem;

            &:hover,
            &:focus {
              background: $hover_green;
              cursor: pointer;
            }
          }
        }
      }
    }

    .header-button {
      border: none;
      background: $primary_green;
      color: $dark_grey;
      border-radius: 1.25rem;
      font-weight: 700;
      padding: 0.5rem 1.5rem;

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
