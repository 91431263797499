@import "utils/variables";

.phone-banner-background {
  background: linear-gradient(99deg, #94D4D1 0.33%, #D0E88A 100%);
  min-height: 38rem;

  @media (max-width: 768px) {
    min-height: unset;
  }
}

.phone-banner-container {
  display: flex;
  padding: 2.5rem;

  @media (min-width: 769px) and (max-width: 1024px){
    flex-direction: column;
    padding: 2.5rem 2.5rem 4.5rem;
  }

  @media (max-width: 768px) {
    height: 34.375rem;
    padding: 0;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
  }

  .phone-header-title {
    margin-top: 5rem;
    margin-right: 4rem;
    min-width: 35%;

    @media (min-width: 769px) and (max-width: 1024px){
      margin: 0.5rem 0 3rem;
    }

    @media (max-width: 768px) {
      margin: 2.75rem 0 2.5rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
      font-family: "WalsheimBold", serif;
      max-width: 22.5rem;

      @media (min-width: 769px) and (max-width: 1024px){
        font-size: 2.5rem;
        line-height: 3rem;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        max-width: 100%;
        font-size: 2.125rem;
        line-height: 2.5rem;
        text-align: left;
      }

    }

    p.phone-header-subtitle {
      font-size: 1.5rem;
      line-height: 2rem;
      max-width: 20.5rem;
      margin-top: 1.5rem;

      @media (min-width: 769px) and (max-width: 1024px){
        font-size: 1.125rem;
        line-height: 1.625rem;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        max-width: 100%;
        font-size: 1.25rem;
        line-height: 1.625rem;
        text-align: left;
      }
    }
  }

  .phone-banner-image {
    min-width: 60%;
    position: relative;
    .main-image {
      height: 28rem;
      width: 100%;
      background-size: cover;
      border-radius: 1rem;
      background-position: left 55% top 50%;

      @media (min-width: 769px) and (max-width: 1024px){
        height: 24rem;
      }

      @media (min-width: 722px) and (max-width: 768px) {
        height: 21rem;
      }

      @media (min-width: 362px) and (max-width: 721px) {
        height: 17rem;
      }

      @media (max-width: 361px) {
        height: 13rem;
      }

      img {
        border-radius: 1rem;
      }
    }
    .overlay-image {
      position: absolute;
      bottom: -2.5rem;
      left: 1.5rem;

      @media (min-width: 769px) and (max-width: 1024px){
        bottom: -2.5rem;
        left: 1.5rem;
      }

      @media (max-width: 768px) {
        bottom: -1.5rem;
        left: 0.5rem;
      }

      img {
        width: 20.65rem;
        height: auto;

        @media (min-width: 769px) and (max-width: 1024px){
          width: 18.65rem;
          height: auto;
        }

        @media (max-width: 768px) {
          width: 12rem;
          height: auto;
        }
      }
    }
  }
}

.home-phone-panel-container {
  .single-select-container {
    margin: 2.5rem 3rem;
  }
}

.phone-badges {
  display: flex;
  justify-content: space-evenly;
  margin: 5.375rem 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    div {
      margin-bottom: 2.5rem;
    }
  }

  div {
    display: flex;
    align-items: center;
    width: 16%;
    margin: 2rem 1rem;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 25%;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem 0;
    }

    p {
      margin-top: 1.25rem;
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

.phone-rate-dropdown__control {
  max-width: 100% !important;

  @media (max-width: 480px) {
    width: 100% !important;
  }
}

.phone-rate-dropdown__menu {
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 90% !important;
  }

  @media (max-width: 480px) {
    width: 90% !important;
    max-width: 100% !important;
  }
}

.phone-select-container {
  background: $purple;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .phone-select-content-container {
    display: flex;
    justify-content: space-between;
    padding: 1.875rem 3rem;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }

    .phone-select-content,
    .phone-select-image {
      padding-top: 2.5rem;

      @media (min-width: 769px) and (max-width: 1024px) {
        width: 45%;
        padding-top: 0;
      }

      .phone-select-content-title {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      .phone-select-content-text {
        font-size: 1.25rem;
        line-height: 1.625rem;
        margin: 2.5rem 0;
      }

      .phone-select-content-dropdown {
        width: 100%;
      }

      .phone-select-rate-container {
        &:not(.is-tablet-only) {
          margin-top: 1.875rem;
        }

        @media (max-width: 768px) {
          margin: 1.875rem 0;
        }

        .phone-select-rate-title {
          font-size: 1.5rem;
          line-height: 2rem;
          margin-bottom: 0.625rem;

          @media (max-width: 480px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }

        .phone-select-rate-text {
          font-size: 1.25rem;
          line-height: 1.625rem;

          @media (max-width: 480px) {
            font-size: 1rem;
            line-height: 1.375rem;
          }
        }
      }
    }
  }
}
