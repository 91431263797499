@import "utils/variables";

.select-modal-container {
  padding: 80px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;

  @media (max-width: 600px) {
    padding: 40px 10px;
    height: 300px;
  }

  .select-modal-button-container {
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
    width: 400px;
    margin-bottom: 1.25rem;

    @media (max-width: 600px) {
      width: 100%;
      margin-right: 10px;
    }
  }

  p {
    font-size: 16px;
    line-height: 19px;
  }

  button {
    background: $white;
    color: $secondary_forest;
    border: 2px solid $secondary_forest;
    border-radius: 16px;
    margin: 5px 0;
    padding: 5px;
    transition: 0.2s;

    &:hover,
    &:active {
      background: $secondary_forest;
      color: $white;
      border: 2px solid $white;
      cursor: pointer;
    }
  }
}
