@import "utils/variables";

.internet-speeds-101-header {
  background-image: url("../../assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 25rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2.5rem;
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;

    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 46.25rem;
    text-align: center;
    margin-top: 2.5rem;

    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media (max-width: 48rem) {
      font-size: 1.25rem;
      line-height: 1.375rem;
      width: 90%;
    }

    p {
      margin: 0 auto;
    }
  }
}

.how-is-internet-measured-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65rem;
  margin: 0 auto 4.5rem;
  flex-wrap: nowrap;

  @media (max-width: 64rem) {
    flex-wrap: wrap;
    margin: 2rem 1.75rem;
  }

  .left-content {
    width: 42%;
    margin-right: 1rem;

    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: left;
      width: 100%;
      margin-right: unset;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: left;
      width: 100%;
      margin-right: unset;
      margin-bottom: 1.5rem;
    }

    h2 {
      margin-bottom: 1rem;

      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @media (max-width: 48rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    p {
      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      @media (max-width: 48rem) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
  .right-content {
    width: 58%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3.75rem;
    line-height: 4rem;
    font-weight: 700;
    letter-spacing: -0.05rem;
    color: $secondary-forest;
    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      font-size: 3rem;
      line-height: 3.5rem;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
    }

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 2.5rem;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      letter-spacing: -0.10rem;
    }
  }
}

.how-internet-speed-works-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 4.5rem;
  max-width: 65rem;
  @media (max-width: 64rem) {
    margin: 0 auto 1rem;
  }
  .how-internet-speed-works-header {
    margin: 0 auto 2.5rem;
    @media (max-width: 64rem) {
      margin: 2rem 1.75rem;
    }
    h2 {
      font-size: 3rem;
      font-weight: 700;
      line-height: 3.5rem;

      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @media (max-width: 48rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
  .how-internet-speed-works-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    div[class*="internet-speeds-work-item"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 1.75rem;
      border-radius: 1rem;
      border: 2px solid $black;
      width: 43%;
      margin-bottom: 2rem;
      @media (max-width: 64rem) {
        width: 100%;
      }
      .internet-speeds-work-content {
        margin: 0 0 2rem;
        p {
          @media (min-width: 48.0125rem) and (max-width: 64rem) {
            font-size: 1.5rem;
            line-height: 2rem;
          }

          @media (max-width: 48rem) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }
    }
    div[class*="internet-speeds-work-item-0"] {
      width: 100%;
      flex-direction: row;
      padding: 2rem 1.75rem 1.5rem;
      align-items: unset;
      justify-content: space-between;
      @media (max-width: 64rem) {
        flex-direction: column;
        align-items: center;
      }
      .internet-speeds-work-content {
        width: 30%;
        @media (max-width: 64rem) {
          width: 100%;
        }
      }
      .internet-speeds-work-image {
        width: 70%;
        @media (max-width: 64rem) {
          width: 100%;
        }
      }
    }
  }
}

.testing-internet-speed-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 8rem;
  max-width: 65rem;
  @media (min-width: 48.0125rem) and (max-width: 64rem) {
    margin: 0 auto 5rem;
  }
  @media (max-width: 48rem) {
    margin: 0 auto 5rem;
  }
  .testing-internet-speed-header {
    width: 50%;
    margin-bottom: 2rem;
    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      margin: 2rem 1.75rem;
      width: 80%;
    }
    @media (max-width: 48rem) {
      margin: 2rem 1.75rem;
      width: 80%;
    }
    h2 {
      margin-bottom: 1rem;
      font-size: 2rem;
      line-height: 2.5rem;
      @media (max-width: 48rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    p {
      font-size: 1.5rem;
      line-height: 2rem;
      @media (max-width: 48rem) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
  .testing-internet-speed-content {
    display: flex;
    flex-direction: column;
    .testing-internet-speed-item {
      display: flex;
      justify-content: space-between;
      @media (max-width: 64rem) {
        flex-wrap: wrap;
      }
      &:last-of-type {
        .testing-internet-speed-number-image {
          .line {
            border-left: unset;
            @media (min-width: 48.0125rem) and (max-width: 64rem) {
              border-left: 0.125rem solid $black;
              &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: -0.5125rem;
                border-top: 0.5rem solid transparent;
                border-bottom: 0.5rem solid black;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
              }
            }
            @media (max-width: 48rem) {
              border-left: 0.05rem solid $black;
              &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: -0.5125rem;
                border-top: 0.5rem solid transparent;
                border-bottom: 0.5rem solid black;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
              }
            }
          }
        }
        .testing-internet-speed-item-content {
          .testing-internet-speed-image {
            margin-bottom: 0;
          }
        }
      }
      &:first-of-type {
        .testing-internet-speed-description {
          padding-top: 2rem;
          @media (max-width: 48rem) {
            padding-top: 0;
          }
        }
      }
      .testing-internet-speed-number-image {
        width: 15%;
        position: relative;
        @media (max-width: 48rem) {
          width: 25%;
        }
        .line {
          position: absolute;
          border-left: 0.125rem solid $black;
          height: 100%;
          left: 50%;
          margin-left: -3px;
          top: 0;
          z-index: -1;
          @media (max-width: 48rem) {
            border-left: 0.05rem solid $black;
          }
        }
      }
      .testing-internet-speed-item-content {
        display: flex;
        width: 83%;
        justify-content: space-between;
        flex-direction: row;
        @media (min-width: 48.0125rem) and (max-width: 64rem) {
          flex-direction: column;
        }
        @media (max-width: 48rem) {
          flex-direction: column;
          width: 70%;
        }
        .testing-internet-speed-description {
          width: 35%;
          margin-bottom: 2rem;
          p {
            font-size: 1.25rem;
            line-height: 1.75rem;
          }
          @media (max-width: 64rem) {
            width: 100%;
          }
        }
        .testing-internet-speed-image {
          width: 55%;
          margin-bottom: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 64rem) {
            margin-bottom: 4rem;
            width: 100%;
          }
        }
      }
    }
  }
  .testing-internet-speed-bottom {
    margin-top: 2rem;
    p {
      width: 65%;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2rem;
      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 1.25rem;
        line-height: 1.75rem;
        width: 80%;
      }
      @media (max-width: 48rem) {
        width: 100%;
      }
    }
  }
}

.how-much-internet-you-need-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 4.5rem;
  max-width: 65rem;
  .how-much-internet-you-need-header {
    width: 60%;
    margin-bottom: 2rem;
    @media (min-width: 48.0125rem) and (max-width: 64rem) {
      width: 100%;
    }
    @media (max-width: 48rem) {
      width: 100%;
    }
    h2 {
      margin-bottom: 1rem;
      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      @media (max-width: 48rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    p {
      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media (max-width: 48rem) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}

.more-speed-than-you-need-container {
  align-items: center;
  background: #eafcc3;
  display: flex;
  flex-direction: column;
  margin: 4rem auto 0;
  max-width: 100%;
  min-height: 8.75rem;
  justify-content: center;
  .more-speed-than-you-need-content {
    max-width: 40rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem;
    @media (max-width: 64rem) {
      padding: 4rem 3.5rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 3rem;
      line-height: 3.5rem;
      @media (max-width: 64rem) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    p {
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.625rem;
      @media (min-width: 48.0125rem) and (max-width: 64rem) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      @media (max-width: 48rem) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    .page-link-container {
      margin-top: 2.5rem;
    }
  }
}