@import "utils/variables";

.channel-lineup-container {

  .channel-header-container {
    display: flex;
    flex-direction: column;
    margin: 6.25rem auto;

    h1 {
      font-size: 4rem;
      line-height: 4.5rem;

      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    p:nth-of-type(1) {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 2.5rem 0;
      width: 34.375rem;

      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.625rem;
        width: 95%;
      }
    }

    p:nth-of-type(2) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  a {
    color: $secondary_forest;
  }

  table,
  th,
  td {
    border-spacing: 0;
    height: 100%;
    box-sizing: border-box;
    outline: 1px solid $black;
  }

  .table-content {
    overflow: scroll;
    padding: 0.125rem;
  }

  table {
    margin: 0 auto;
    border-radius: 1rem;
    border-collapse:collapse;
    border-spacing: 1px;


    th:not(:first-of-type) {
      font-size: 1rem;
      line-height: 1.375rem;
      padding: 0.75rem;
      text-align: left;
      box-sizing: border-box;
      background: $primary_green;

      span {
        position: relative;
        left: 0.375rem;
        top: 0.125rem;
      }

      &:hover {
        cursor: pointer;
      }
    }

    th:first-of-type {
      border-top-left-radius: 1rem;
      font-size: 1rem;
      line-height: 1.375rem;
      padding: 0.75rem;
      text-align: left;
      box-sizing: border-box;
      background: $primary_green;

      span {
        position: relative;
        left: 0.375rem;
        top: 0.125rem;
      }
    }
    th:last-of-type {
      border-top-right-radius: 1rem;
    }
    tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 1rem;
    }
    tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 1rem;
    }

    th:nth-of-type(1),
    th:nth-of-type(6) {
      width: 18.75rem;
    }

    th:nth-of-type(2),
    th:nth-of-type(3),
    th:nth-of-type(4),
    th:nth-of-type(5) {
      width: 11.625rem;
    }

    @media (max-width: 1024px) {
      th:nth-of-type(1) {
        position: sticky;
        left: 0;
        width: 7.875rem;
        z-index: 2;
      }
    }
  }
}
