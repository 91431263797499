@import "utils/variables";

.tc-component-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin: 1.5rem 0;

  &.flex-reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column !important;
  }

  .text {
    width: 47%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .title-text {
      margin: 0 0 1.5rem;

      h2 {
        color: $spinach_green;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 1024px) {
          text-align: center;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
          line-height: normal;
          font-weight: 700;
        }
      }
    }

    .sub-text {
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;

      }

      li {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
      }
    }
  }
  .media {
    width: 47%;
    display: flex;
    justify-content: left;
    align-items: flex-start;

    @media (max-width: 1024px) {
      width: 100%;
    }

    &.flex-reverse {
      justify-content: right;
      align-items: flex-start;
    }

    .media-video, .media-image {
      width: 100%;
      height: 100%;
      text-align: left;

      img.tcc-image {
        border-radius: 1.5rem;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      &.flex-reverse {
        text-align: right;
      }
    }
  }
}
