@import "utils/variables";

.stepThree_left_block_container {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  width: 100%;

  @media screen and (max-width: 48rem) {
    display: flex;
    flex: 1;
    width: 100%;
    // margin-top: 3rem;
  }

  .stepThree_left_block_container_mobile {
    display: flex;
    flex-direction: column;
    flex: 0.7;
    width: 100%;

    @media screen and (max-width: 48rem) {
      display: flex;
      flex: 1;
      width: 100%;
      margin-top: 3rem;
    }
  }

  @media (max-width: 78.375rem) {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .stepThree_yourInfo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;

    @media screen and (max-width: 48rem) {
      margin-bottom: 2rem;
    }
  }

  //ITS OUTSIDE BECAUSE 2 COMPONENTS ARE USING IT ON A DIFFERENT LEVEL
  .stepThree_btn_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
  }
}

// RIGHT BLOCK CONTAINER
.orderPage_right_block_container {
  @media screen and (max-width: 48rem) {
    margin-bottom: 1.5rem !important;
  }
}
