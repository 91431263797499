@import "utils/variables";

.overflow-carousel-container {
  .slick-prev {
    z-index: 1;
    left: 0px;
    top: 0px;
  }

  .slick-next {
    z-index: 1;
    right: 30px;
    top: 0px;
  }

  img {
    margin: 0 auto;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin: 20px 0;

    @media (max-width: 800px) {
      right: 0;
    }
  }

  p {
    font-size: 24px;
    text-align: center;
    margin: 1em;

    @media (max-width: 800px) {
      right: 0;
    }
  }

  .overflow-carousel-card {
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  }
}
