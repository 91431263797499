@import "utils/variables";

.hamburger-container {
  display: none;
  background: $white;
  padding-bottom: 1px;

  @media (max-width: 768px) {
    display: block;
  }
}

.hamburger-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.25rem;
  margin-right: 0.375rem;
}

.hamburger-menu {
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  overflow: hidden;

  .hamburger-text {
    color: $dark_grey;
    background: $primary_green;
    width: fit-content;
    margin: 3.125rem auto 0.625rem;
    padding: 0.625rem 1.875rem;
    border-radius: 1.25rem;
    font-weight: 700;
  }

  button {
    margin: 0 0.625rem;
  }
}

.hamburger-menu[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.hamburger-shop-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 1rem 0.625rem 1.25rem;

  &:hover {
    cursor: pointer;
  }
}

.hamburger-shop {
  overflow: hidden;
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  background: $hover_green;
  padding-left: 1.875rem;

  a {
    color: $black;
  }

  p {
    margin: 1.25rem 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.hamburger-shop[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.hamburger-shop > p {
  cursor: pointer;
}

.hamburger-about-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1rem 0.625rem 1.25rem;

  &:hover {
    cursor: pointer;
  }
}

.hamburger-about > p {
  cursor: pointer;
}

.hamburger-about {
  overflow: hidden;
  max-height: 62.5rem;
  transition: max-height 1s ease-in-out;
  background: $hover_green;
  padding-left: 1.875rem;

  a {
    color: $black;
  }

  p {
    margin: 1.25rem 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.hamburger-about[aria-expanded="true"] {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.hamburger-carrot {
  transition: 0.5s all;
}

.hamburger-carrot[aria-expanded="false"] {
  transform: rotate(0.5turn);
}

.hamburger-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1rem 0.625rem 1.25rem;
  cursor: pointer;
  a {
    color: $black;
  }
}

.hamburger-sign-in {
  display: flex;
  margin: 0.625rem 0;
  padding-left: 1.25rem;

  a {
    margin-left: 0.625rem;
    color: $black;
  }

  p {
    margin-left: 0.625rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.hamburger-search {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.625rem 0;
  margin-left: 1.25rem;

  .hamburger-search-bar {
    border-radius: 1.25rem;
    border: 1px solid $white;
    background: $white;
    height: 1.5rem;
    width: 15.625rem;
    color: $black;
    margin-left: -0.625rem;
    padding-left: 2.25rem;

    // style search text here
    &::placeholder {
      color: $black;
      position: absolute;
      left: 2.25rem;
      bottom: 0.25rem;
    }
  }

  .hamburger-search-bar:focus {
    outline: 0.125rem solid $black;

    &::placeholder {
      color: $white;
    }
  }

  .hamburger-search-icon {
    position: absolute;
    left: 0;
  }
}
