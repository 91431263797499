@import "utils/variables";

.availability_container {
  display: flex;

  .availability_wrapper {
    display: flex;
    flex-direction: column;
    width: 73rem;
    margin: 0 auto;
    padding: 0 1rem;

    .availability_header {
      margin: 0 auto;
      margin-top: 3.75rem;
      margin-bottom: 4.375rem;
      max-width: 49rem;

      @media screen and (max-width: 768px) {
        margin-top: 2.75rem;
        margin-bottom: 3.125rem;
      }

      & > h1 {
        font-size: 3.875rem;
        font-weight: 700;
        line-height: 4.5rem;
        letter-spacing: -0.04rem;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 2.25rem;
          line-height: 3rem;
          text-align: left;
        }
      }

      & > p {
        margin-top: 1.875rem;
        font-size: 1.25rem;
        line-height: 1.625rem;
        text-align: center;

        @media screen and (max-width: 768px) {
          text-align: left;
        }
      }
    }
    .availability_map_controls {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      padding-bottom: 2rem;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        column-gap: 0.25rem;
        row-gap: 1.5rem;
      }

      & > button {
        background-color: transparent;
        border: none;
        color: #000;
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-family: 'WalsheimRegular';

        @media screen and (max-width: 768px) {
          font-size: 1.15rem;
          line-height: 1.25rem;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .selected {
        color: $secondary_forest;
        font-weight: 700;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -5px;
          left: 6px;
          width: calc(100% - 12px);
          height: 3px;
          background: $secondary_forest;
          transition: width 0.3s;
        }
      }
    }
    .availability_map_wrapper {
      overflow: hidden;
      width: 100%;
      height: 37.5rem;
      border-radius: 1.5rem;

      .availability_map_loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18.75rem;
        span svg {
          color: $secondary_forest;
        }
      }

      .offnet_container {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25);
        border-radius: 1.375rem;
        overflow: hidden;

        & > h2 {
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.875rem;
          text-align: center;
          color: #fff;
        }

        & > p {
          font-size: 1rem;
          line-height: 1.25rem;
          text-align: center;
          color: #fff;
        }

        .offnet_window_container {
          width: 12.5rem;
          height: fit-content;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background-color: #fff;

          @media screen and (max-width: 768px) {
            width: inherit;
          }

          & > h2 {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.5rem;
            padding: 0;
            margin: 0;
          }

          & > p {
            font-size: 1rem;
            line-height: 1.375rem;
            font-weight: 100;
            font-style: normal;
            margin-top: 0.625rem;
          }

          .offnet_window_service {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-top: 1.5rem;

            & > img {
              object-fit: fill;
              max-width: 1.25rem;
              max-height: 1.25rem;
            }

            & > p {
              font-size: 0.875rem;
              line-height: 1.375rem;
              font-style: normal;
              margin-left: 0.3125rem;
              font-weight: 700;
              white-space: nowrap;
            }
          }
          .offnet_window_button {
            height: 2.5rem;
            width: 100%;
            background-color: $primary_green;
            border: none;
            padding: 0.5rem 1.5rem;
            border-radius: 1.375rem;
            margin-top: 1.5625rem;

            @media screen and (max-width: 768px) {
              padding: 0.125rem 0.625rem;
            }
            &:hover {
              cursor: pointer;
            }

            & > p {
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: 700;
              font-style: normal;
              color: #000000;
              text-decoration: none;
              outline: none;

              @media screen and (max-width: 768px) {
                font-size: 0.875rem;
                line-height: 1.375rem;
              }
            }
          }
        }
      }

      .availability_search {
        position: absolute;
        top: 0;
        right: 6.25rem;
        width: 21.875rem;
        height: 2.8125rem;
        margin-top: 0.625rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          right: 0;
          left: 0.3125rem;
        }

        & > input {
          width: 100%;
          height: 100%;
          border-radius: 1.875rem;
          outline: none;
          border: none;
          padding: 0 1.25rem;
          position: relative;
          z-index: 2;

          @media screen and (max-width: 768px) {
            box-sizing: border-box;
            width: 97%;
          }
        }

        & > svg {
          position: absolute;
          top: 48%;
          right: -1.5625rem;
          transform: translateY(-50%);
          width: 1.25rem;
          height: 1.25rem;
          z-index: 3;

          &:hover {
            cursor: pointer;
          }

          @media screen and (max-width: 768px) {
            right: 2.1875rem;
          }
        }

        .availability_search_dropdown {
          position: absolute;
          top: 100%;
          left: 1.25rem;
          width: 100%;
          max-height: 12.5rem;
          background-color: #fff;
          overflow-y: auto;
          z-index: 0;
          & > div {
            padding: 0.625rem 1.25rem;
            &:hover {
              background-color: $hover_green;
              cursor: pointer;
            }
          }

          &::-webkit-scrollbar {
            width: 0.3125rem;
          }
          @media screen and (max-width: 768px) {
            height: 9.375rem;
            width: 95%;
            left: 0.3125rem;
            box-sizing: border-box;
          }
        }
      }
      .availability_map_description_box {
        position: absolute;
        top: -15rem;
        left: calc(50% - 7.1875rem);
        width: 15.625rem;
        height: 13.4375rem;
        border-radius: 1.5rem;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        padding: 0 2rem;
        box-sizing: border-box;
        transition: opacity 0.3s;
        z-index: 1;

        &::after {
          //arrow pointing down with white bg
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 1.5rem solid transparent;
          border-right: 1.5rem solid transparent;
          border-top: 1.5rem solid rgb(255, 255, 255);
        }

        @media screen and (max-width: 768px) {
          padding: 0 1rem;
        }

        & > h3 {
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.875rem;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }

        & > p {
          font-size: 1rem;
          line-height: 1.25rem;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 0.875rem;
            line-height: 1.125rem;
          }
        }
      }
    }
    .availability_map_marks {
      margin-top: 1rem;
      display: flex;
      margin-left: 2.1875rem;
      gap: 2rem;
      margin-bottom: 6.875rem;

      @media screen and (max-width: 768px) {
        justify-content: space-between;
        margin-left: 0.25rem;
        margin-bottom: 3.125rem;
        gap: 0.65rem;
      }

      @media screen and (max-width: 350px) {
        flex-direction: column;
      }

      .availability_marks {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @media screen and (max-width: 768px) {
          gap: 0;
        }

        & > p {
          font-size: 1rem;
          margin-left: 0.25rem;

          @media screen and (max-width: 370px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
.gm-style-iw {
  border-radius: 1rem !important;
  padding: 0.75rem 0 0 1rem;

  @media screen and (max-width: 768px) {
    width: 18.75rem !important;
  }
}

.gm-ui-hover-effect {
  display: none !important;
}
.info_window_container {
  width: 12.5rem;
  height: fit-content;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: inherit;
  }

  & > h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1rem;
    padding: 0;
    margin: 0;
  }

  & > p {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 100;
    font-style: normal;
    margin-top: 0.625rem;
  }

  .info_window_service {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;

    & > img {
      object-fit: fill;
      max-width: 1.25rem;
      max-height: 1.25rem;
    }

    & > p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-style: normal;
      margin-left: 0.375rem;
      font-weight: 700;
    }
  }
  .info_window_button {
    width: 100%;
    background-color: $primary_green;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 1.375rem;
    margin-top: 1.5rem;

    @media screen and (max-width: 768px) {
      padding: 0.125rem 0.625rem;
    }
    &:hover {
      cursor: pointer;
    }

    & > p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      font-style: normal;
      color: #000000;
      text-decoration: none;
      outline: none;

      @media screen and (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }
}
