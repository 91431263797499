@import "utils/variables";

.banner-component-container {
  width: 100%;
  //max-width: 90rem;
  margin: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;

  @media (max-width: 1024px) {
    align-items: flex-end;
  }

  @media (max-width: 768px) {
    height: unset;
  }

  .left-content {
    max-width: 30rem;
    padding: 1rem;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 3.5rem 3rem;
    }

    .main-text {
      margin-bottom: 2rem;

      p {
        font-size: 2.25rem;
        line-height: normal;
        font-family: 'WalsheimBold', sans-serif;

        @media (max-width: 768px) {
          line-height: 3.5rem;
          text-align: center;
        }
      }
    }

    .secondary-text {
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;

        @media (max-width: 768px) {
          line-height: 1.5rem;
          text-align: center;
        }
      }
    }
  }
}

.right-content {
  max-width: 36rem;
  width: 100%;

  @media (max-width: 1024px) {
    margin: 0;
    max-width: 100%;
  }

  input {
    border-radius: 0.5rem;
    border: 1px solid #000;
    height: 2.5rem;
    font-size: 1rem;
    padding: 0 0.625rem;
  }

  textarea {
    border-radius: 0.5rem;
    border: 1px solid #000;
    font-size: 1rem;
    padding: 0.625rem 0.625rem;
  }

  .form-component-container {
    margin: 2rem;
    padding: 2rem 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid;
    min-height: 40rem;

    &.form-success {
      background-color: #eafcc3 !important;
      border-color: #000000;
    }

    &.form-error {
      background-color: #fce6e6 !important;
      border-color: #cb060a;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      margin: 0;
      border-radius: 0;
      height: unset;
      padding: 2rem 3rem;
    }

    @media (max-width: 768px) {
      margin: 1rem;
      height: unset;
    }

    .submit-loading {
      min-width: 7.5rem;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .form-success-container, .form-error-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .title p {
        font-size: 2rem;
        text-align: center;
        font-family: 'WalsheimBold', serif;
        line-height: 2.5rem;
        width: 23rem;
        margin-bottom: 0.5rem;
      }

      .text p {
        font-size: 1rem;
        text-align: center;
        line-height: 1.5rem;
        width: 20rem;
      }
    }

    .form-header {
      text-align: center;
      margin-bottom: 3.125rem;
      letter-spacing: -1.1%;

      .form-title p {
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-family: 'WalsheimBold', sans-serif;
        margin-bottom: 0.5rem;
      }

      .form-subtitle {
        p {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .contact-split-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column;
          margin-bottom: 0;
        }

        label {
          font-size: 0.75rem;
          line-height: 1rem;
          margin-bottom: 0.375rem;
        }

        .input-container {
          width: 48%;
          display: flex;
          flex-direction: column;

          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 1rem;
          }
        }
      }

      .captcha-subscribe-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      .subscribe-container {
        display: flex;
        justify-content: left;
        align-items: flex-start;
        max-width: 17rem;
        
        @media (min-width: 769px) and (max-width: 1024px) {
          max-width: unset;
        }

        @media (max-width: 768px) {
          max-width: 100%;
        }

        .checkbox-container {
          margin-right: 0.625rem;
        }

        .content-checkbox-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .bold-text p {
            font-weight: 700;
          }

          p {
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }
      }

      button {
        border-radius: 1.375rem;
        width: 12.875rem;
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
        margin-top: 0.75rem;
        background-color: #202020;
      }

      .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;

        label {
          font-size: 0.75rem;
          line-height: 1rem;
          margin-bottom: 0.375rem;
        }

        div {
          width: 100%;
        }
      }
    }
  }
}
