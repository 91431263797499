@import "utils/variables";

.questionnaire-modal-container {
  padding: 4.375rem 11.5625rem 3.75rem 5.9375rem;
  width: 50rem;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 40px;
    width: 100%;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
    width: 37.5rem;

    @media (max-width: 600px) {
      width: 100%;
      margin-right: 10px;
    }
  }

  p {
    margin: 3.125rem 0 1.15rem;
    @media screen and (max-width: 48rem) {
      width: 100%;
    }
  }
  .radio_btn_wrap {
    display: flex;
    align-items: center;
  }
  label {
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  div:nth-of-type(1) {
    margin-bottom: 10px;
  }

  button {
    margin-top: 3.75rem;
    width: 150px;
  }

  input,
  label {
    &:hover {
      cursor: pointer;
    }
  }
}
