@import "utils/variables";

.number-package-container {
  border: 2px solid $black;
  border-radius: 1rem;
  padding: 1.875rem;
  margin: 1.25rem;
  width: 50%;

  &.max-width-set {
    max-width: 25rem;
  }

  @media (max-width: 1024px) {
    &:nth-of-type(1) {
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: 480px) {
    padding: 1.25rem;
    width: 100%;
  }

  .number-package-top {
    display: flex;
    flex-direction: column;
    align-content: space-between;

    h2 {
      align-self: flex-start;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;

      @media (max-width: 480px) {
        font-size: 1.5rem;
      }
    }

    p {
      align-self: flex-start;
      font-size: 1.5rem;
      line-height: 2rem;

      @media (max-width: 480px) {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
    .price-container {
      display: flex;
      margin: 2rem 0;

      .number-dollar {
        color: $secondary_forest;
        font-size: 2.625rem;
        font-weight: 700;
      }

      .number-value {
        color: $secondary_forest;
        font-size: 6.625rem;
        line-height: 4.75rem;
        font-weight: 700;
      }

      .number-text {
        font-size: 2rem;
        position: relative;
      }

      .number-tax {
        font-size: 1rem;
      }

      .extra-info {
        height: 4.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-top: 1.25rem;
    align-content: flex-start;
    li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      p {
        margin: 0 0 0 1rem;
      }
    }
  }

  .number-pack-img-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.625rem;
    grid-row-gap: 1.25rem;
    margin-top: 0.625rem;
  }

  .number-pack-arrow-container {
    position: relative;
    width: fit-content;
    margin-top: 1.875rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
    }

    div {
      position: absolute;
      right: -1rem;
      top: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
