@import "utils/variables";

.info_icon {
  font-family: "WalsheimRegular";
  margin-top: 0.55rem;
  margin-left: 0.25rem;

  &:hover {
    // cursor: pointer;
  }
}
