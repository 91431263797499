.highlight-component-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1.375rem;
  padding: 1.5rem 3.375rem;
  margin: 1.5rem 0;

  .content {
    margin: 0 0 2.5rem 0;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      font-family: 'WalsheimBold', sans-serif;
      text-align: center;
    }
  }

  &.use-column-layout {
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .content {
      margin: 1rem 6rem 1rem 0;

      @media (max-width: 1024px) {
        margin: 0 0 2.5rem 0;
      }

      p {
        max-width: 60rem;

        @media (max-width: 1024px) {
          text-align: center;
          max-width: unset;
        }
      }
    }
  }
}
