@import "utils/variables";

.tv-banner-background {
  background: linear-gradient(99deg, #94D4D1 0.33%, #D0E88A 100%);
  min-height: 38rem;

  @media (min-width: 769px) and (max-width: 1024px){
    min-height: 42rem;
  }

  @media (max-width: 768px) {
    min-height: unset;
  }
}
.tv-banner-container {
  display: flex;
  padding: 2.5rem;

  @media (min-width: 769px) and (max-width: 1024px){
    flex-direction: column;
    padding: 2.5rem 2.5rem 4.5rem;
  }

  @media (max-width: 768px) {
    height: 34.375rem;
    padding: 0;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
  }

  .tv-header-title {
    margin-top: 5rem;
    margin-right: 4rem;
    min-width: 35%;

    @media (min-width: 769px) and (max-width: 1024px){
      margin: 0.5rem 0 3rem;
    }

    @media (max-width: 768px) {
      margin: 2.75rem 0 2.5rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
      font-family: "WalsheimBold", serif;

      @media (min-width: 769px) and (max-width: 1024px){
        font-size: 2.5rem;
        line-height: 3rem;
      }

      @media (max-width: 768px) {
        font-size: 2.125rem;
        line-height: 2.5rem;
        text-align: left;
      }
    }

    p.tv-header-subtitle {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 1.5rem;

      @media (min-width: 769px) and (max-width: 1024px){
        font-size: 1.125rem;
        line-height: 1.625rem;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.625rem;
        text-align: left;
      }
    }
  }

  .tv-banner-image {
    min-width: 60%;
    position: relative;
    .main-image {
      height: 28rem;
      width: 100%;
      background-size: cover;
      border-radius: 1rem;
      background-position: left 55% top 50%;

      @media (min-width: 769px) and (max-width: 1024px){
        height: 24rem;
      }

      @media (min-width: 603px) and (max-width: 768px) {
        height: 17rem;
      }

      @media (max-width: 602px) {
        height: 15rem;
      }

      img {
        border-radius: 1rem;
      }
    }
    .overlay-image {
      position: absolute;
      bottom: -5.5rem;
      left: -4.5rem;
      right: unset;

      @media (min-width: 769px) and (max-width: 1024px){
        bottom: -3.5rem;
        left: 1.5rem;
        right: unset;
      }

      @media (max-width: 768px) {
        bottom: -3.5rem;
        left: unset;
        right: 0.5rem
      }

      img {
        width: 18rem;
        height: auto;

        @media (min-width: 769px) and (max-width: 1024px){
          width: 15rem;
          height: auto;
        }

        @media (max-width: 768px) {
          width: 9.5rem;
          height: auto;
        }
      }
    }
  }
}

.tv-package-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  .tv-package-title-container {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      margin: 0 20px;

      @media (max-width: 500px) {
        font-size: 32px;
        line-height: 40px;
        margin: 0;
      }
    }

    p {
      padding: 40px 0;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
      line-height: 32px;

      max-width: 40.625rem;

      @media (max-width: 500px) {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
      }
    }
  }

  .tv-package {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
  }
}

.tv-badge-container {
  display: flex;
  justify-content: space-evenly;
  margin: 8rem auto;

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-wrap: wrap;
    margin: 5rem auto;

  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 3rem auto;
    align-items: center;

    div {
      margin-bottom: 2.5rem;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 16rem;

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 50%;
      margin-bottom: 3rem;
    }

    p.badge-title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    p {
      margin-top: 1.25rem;
      text-align: center;
      font-size: 1rem;
    }
  }
}

.tv-marquee-container {
  background-color: $purple;

  .resi-container {
    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .tv-marquee-content {
    display: flex;
    margin-top: 5rem;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 3.5rem auto 0;
      width: 100%;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 50%;

      @media (max-width: 768px) {
        width: unset;
        padding: 2rem;
      }
    }
    .right-content {
      width: 50%;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  h2 {
    font-size: 3rem;
    text-align: left;
    line-height: 3.5rem;
    padding-bottom: 1rem;
    max-width: 22.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  p {
    max-width: 22.5rem;
    padding-bottom: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}

.tv-content-container {
  display: flex;
  margin-top: 9.375rem;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .tv-content-text {
    text-align: center;
    @media (max-width: 768px) {
      max-width: 80%;
    }
  }

  .tv-content-img {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 2rem;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    padding-bottom: 2.5rem;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
      padding-bottom: 1.5rem;
    }
  }

  p {
    width: 18.75rem;
    padding-bottom: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}
