@import "utils/variables";

.one-gb-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3.75rem 5rem 1.875rem;
  height: fit-content;
  max-width: 38.75rem;

  @media (max-width: 480px) {
    margin: 1.25rem 0.625rem;
    padding: 1.25rem 2.5rem 1.875rem;
  }
  .one-gb-container-header {
    h2 {
      font-size: 1.625rem;
      line-height: 2rem;
      text-align: center;
      &:last-of-type {
        padding-bottom: 1rem;
      }
    }
    p {
      text-align: center;
      max-width: 32rem;
      margin: 0 auto;
      &:first-of-type {
        margin: 0 auto 1rem;
      }
    }

    .fibrestream-modal-text {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
      margin-top: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .one-gb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    @media screen and (max-width: 480px) {
      margin-top: 2.5rem;
    }

    button {
      padding: 0.5rem 3rem;
    }

    .one-gb-modal-text {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
      margin-top: 2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .switch-region-container {
    display: flex;
    color: $warning;
    background-color: $warning_background;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    margin: 0 0 1rem;
    align-items: center;
    justify-content: space-between;

    .left-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      svg path {
        fill: $warning;
      }
    }
    .right-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        margin-left: 1.5rem;
      }

      span {
        color: $secondary_forest;
        min-width: 12.50rem;
        &:hover {
          cursor: pointer;
        }
        text-align: right;
      }
    }

    @media screen and (max-width: 48rem) {
      padding: 1.5rem 2rem;

      .left-content {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 0;
      }
      .right-content {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        p {
          margin-left: 0;
          width: 100%;
        }
        span {
          margin-top: 0.35rem;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
