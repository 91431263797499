@import "utils/variables";

tr {
  td {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;

    p {
      font-size: 16px;
    }
  }

  .table-row-image-container {
    display: flex;
    align-items: center;

    // .table-row-title {
    //   text-align: ;
    // }

    img {
      margin-right: 5px;
    }

    @media (max-width: 1125px) {
      position: sticky;
      left: 0;
      background: $white;
      flex-direction: column;
    }
  }
}
