@import "utils/variables";

.address-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6.25rem 5rem 3.75rem;

  @media (max-width: 768px) {
    margin: 2.5rem 0.625rem;
  }

  h1 {
    font-size: 1.625rem;
    line-height: 2rem;
    text-align: center;
  }

  .address-modal-input-container {
    display: flex;
    position: relative;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .address-modal-dropdown {
      position: absolute;
      top: 4.125rem;
      left: 1.125rem;
      background: $white;
      border-radius: 0 0 1rem 1rem;
      max-width: 21rem;

      @media (max-width: 480px) {
        width: 12.75rem;
        z-index: 1;
        top: 4.875rem;
      }

      p {
        padding: 0.5rem;

        &:last-child {
          border-radius: 0 0 1rem 1rem;
        }

        &:hover {
          background: $hover_green;
          cursor: pointer;
        }
      }
    }

    .address-error-container {
      position: relative;
      &:first-of-type {
        z-index: 2;
      }

      input[aria-border="true"] {
        border: 1px solid $error;
      }

      .input-error-text {
        position: absolute;
        top: 4.25rem;
        left: 1.125rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $error;
      }
    }

    .address-modal-address-input {
      width: 18.625rem;
      padding: 0.625rem 1.25rem;
      border-radius: 1.625rem;
      border: 1px solid #201f1f;
      font-size: 1rem;
      line-height: 1.375rem;
      margin: 1.25rem 1rem 1.25rem 0;

      @media (max-width: 480px) {
        width: 12.5rem;
        margin: 2rem 0 0.25rem;
      }
    }

    .address-modal-unit-input {
      width: 3.125rem;
      padding: 0.625rem 1.25rem;
      border-radius: 1.625rem;
      border: 1px solid #201f1f;
      font-size: 1rem;
      line-height: 1.375rem;
      margin: 1.25rem 0;

      @media (max-width: 480px) {
        width: 12.5rem;
        margin: 0.25rem 0 2rem;
      }
    }
  }
}
