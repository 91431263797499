@import "utils/variables";
// ACTIVE CONTAINER
.step_one_mobile_container {
  .active_step_wrapper {
    position: relative;
    border-left: 2px solid #c7c7c7;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;

    .active_step_title_wrapper {
      display: flex;
      align-items: center;
      background-color: white;
      position: absolute;
      top: 0;
      left: -1.1rem;

      .margin_top_2 {
        margin-top: 3.5rem;
      }

      .step_number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border: 2px solid $primary_green;
        border-radius: 50%;
        font-size: 1.25rem;
        background-color: white;
        z-index: 100;
      }
    }
  }

  .margin_top_2 {
    margin-top: 3.5rem;
  }

  .padding_top_2 {
    padding-top: 3.5rem;
  }
  .padding_top_4 {
    padding-top: 5.5rem;
  }

  .margin_top_3 {
    margin-top: 3.5rem;
  }

  .margin_top_4 {
    margin-top: 4rem;
  }

  .test_line {
    position: relative;
    background-color: white;
    z-index: 10000;
  }
}
// INACTIVE STEP
.step_two_inactive_mobile_container {
  border-left: 2px solid #c7c7c7;
  position: relative;
  margin-left: 0.5rem;

  &.height_0 {
    height: 9rem;
  }

  &.height_1 {
    height: 6rem;
  }

  &.height_2 {
    height: 3rem;
    margin-bottom: 4.0625rem;
  }

  &.height_3 {
    height: 2rem;
  }

  .inactive_step_wrapper {
    display: flex;
    align-items: center;
    color: gray;
    z-index: 100;
    background-color: white;
    position: absolute;
    left: -1.1rem;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 3rem;
    }
    &:nth-child(3) {
      top: 6rem;
    }
    &:nth-child(4) {
      top: 9rem;
    }
    &:nth-child(5) {
      top: 12rem;
    }

    .inactive_step_number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: 1px solid gray;
      border-radius: 50%;
      font-size: 1.25rem;
      z-index: 100;
      background-color: white;
    }
    .inactive_step_text {
      margin-left: 0.5rem;
    }
  }
}
//COMPLETED STEP
.height_completed_3 {
  height: 7rem !important;
}
.height_completed_4 {
  height: 15rem !important;
}

.step_two_completed_mobile_container {
  border-left: 2px solid $primary_green;
  position: relative;
  margin-left: 0.5rem;
  height: 3.5rem; // should be dynamic

  .completed_step_wrapper {
    display: flex;
    align-items: center;
    color: $btn_dark;
    z-index: 100;
    background-color: white;
    position: absolute;
    left: -1.1rem;

    &:nth-child(1) {
      top: 0rem;
    }

    &:nth-child(2) {
      top: 3.5rem;
    }

    &:nth-child(3) {
      top: 7rem;
    }

    &:nth-child(4) {
      top: 10.5rem;
    }

    &:nth-child(5) {
      top: 14rem;
    }

    .step_number_complete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: 2px solid $primary_green;
      border-radius: 50%;
      font-size: 1.25rem;
      background-color: white;
      z-index: 100;
      background-color: $primary_green;
    }

    .completed_step_number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: 2px solid $primary_green;
      border-radius: 50%;
      font-size: 1.25rem;
      z-index: 100;
      background-color: white;
    }
    .completed_step_text {
      margin-left: 0.5rem;
    }
  }
}
