@import "utils/variables";

.single-select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0;

  @media (max-width: 1024px) {
    flex-direction: column;

    .single-select-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 768px) {
        h2 {
          width: 85%;
          font-size: 2.125rem;
          line-height: 2.5rem;
          text-align: center;
        }
      }
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 0.625rem;
  }

  .single-desktop-button {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .single-mobile-button {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .single-package-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .single-spacer-text {
    font-family: "WalsheimRegular";
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.25rem 0 2.5rem;
    max-width: 32rem;

    p {
      line-height: 2rem;
    }

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.625rem;
      width: 95%;
      margin-top: 1rem;
    }
  }

  .single-spacer-text.subtitle {
    font-size: 1rem;
  }

  .single-text-container {
    display: flex;
    align-items: flex-end;

    .single-large {
      color: $secondary_forest;
      font-size: 6.75rem;
      line-height: 4.75rem;
    }

    .single-medium {
      font-size: 3.25rem;
      line-height: 3.75rem;
      position: relative;
      top: 0.625rem;
    }

    .single-small {
      font-size: 2rem;
      line-height: 2.25rem;
    }

    .single-dollar {
      color: $secondary_forest;
      font-size: 2.625rem;
      line-height: 3rem;
      position: relative;
      bottom: 2.375rem;
    }

    .single-divider {
      background: $secondary_forest;
      height: 4.75rem;
      width: 0.25rem;
      margin: 0 1.25rem;

      @media (max-width: 1024px) {
        height: 0.25rem;
        width: 12.5rem;
        margin: 1.25rem 0;
      }
    }
  }

  .month-tax-container {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .month-tax-text {
      display: flex;
      align-items: flex-end;

      .extra-info {
        height: 4.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }
}
