@import "utils/variables";

.contact-header {
  background-image: url("/assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin: 1.25rem auto 0;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h2 {
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 1.25rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  p {
    font-size: 2rem;
    line-height: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      line-height: 1.625rem;
      text-align: center;
    }
  }
}

.contact-location-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .contact-location {
    display: flex;
    align-items: center;
    margin: 2.5rem 0 5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    div {
      width: 19.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1rem;
      margin-right: 1rem;

      @media (max-width: 768px) {
        &:nth-of-type(2) {
          margin: 2.5rem 0;
        }
      }

      h4 {
        margin-bottom: 0.625rem;
      }

      h4,
      p {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
  }
}

.contact-form-bg {
  background: $light_green;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.25rem;
  padding: 3.75rem 0;

  .contact-form-container {
    max-width: 43.75rem;
    margin: auto;

    @media (max-width: 768px) {
      width: 90%;
    }

    .contact-form-header {
      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.375rem;
        margin-top: 1.25rem;
      }

      .contact-warning-text {
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 1.25rem;
      }
    }

    .contact-form {
      width: 100%;
      margin-bottom: 1.25rem;

      input,
      textarea {
        border-radius: 0.5rem;
        border: 1px solid $black;
        font-size: 1rem;
        line-height: 1.375rem;
        padding: 0.325rem;
      }

      input[aria-errormessage="true"],
      textarea[aria-errormessage="true"] {
        border: 1px solid $error;
        background: $error_input_background;
      }

      textarea {
        min-width: 98%;
        min-height: 9.375rem;
      }

      label {
        font-size: 0.75rem;
        line-height: 1rem;
        margin: 1.25rem 0 0.325rem;
      }

      div {
        display: flex;
        flex-direction: column;
      }

      .contact-split-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        div {
          width: 21.25rem;

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .contact-error-text {
      color: red;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  .contact-thanks-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.375rem;
      margin-top: 0.325rem;
    }
  }
}
