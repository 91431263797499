.stepTwo_main_container {
  display: flex;
  flex: 1;

  // MOBILE VIEW
  .step_two_mobile_container {
    @media screen and (max-width: 48rem) {
      margin-bottom: 4rem;
    }
    .mobile_your_address_container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .mobile_calendar_container {
      display: flex;
      height: 26.25rem;
      margin-top: 1.5625rem;

      @media screen and (max-width: 48rem) {
        height: auto;
        margin-bottom: 1.5rem;
      }
    }
    .mobile_button_container {
      display: flex;
      flex: 1;
      margin-top: 2rem;
      margin-left: 1.75rem;
      margin-bottom: 3.4375rem;

      @media screen and (max-width: 48rem) {
        flex-direction: column;
        margin-bottom: 2rem;
      }
    }

    .mobile_availability_container {
      flex: 1;
      margin-top: 5rem;
      margin-left: 3.125rem;
      flex-basis: start;

      @media screen and (max-width: 48rem) {
        margin-top: 0rem;
        margin-left: 1.75rem;

        h2 {
          line-height: 1.75rem;
          text-transform: capitalize;
        }

        span {
          display: inline-block;
          margin-top: 0.9375rem;
        }
      }

      .mobile_availability_buttons_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.9375rem;
        margin-top: 1.875rem;

        button {
          width: 100%;
          max-width: 14.375rem;
        }

        @media screen and (max-width: 48rem) {
          margin-top: 1rem;
          flex: 1;
          button {
            width: 100%;
            max-width: none;
            border: 1px solid #4b4a4a;
          }
        }
      }
    }
    .mobile_want_sooner_container {
      display: flex;
      margin-top: 2rem;
      margin-left: 1.75rem;

      &.hidden {
        display: none;
      }

      .stepTwo-want-sooner-left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .want-sooner-title {
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5rem;
          margin-bottom: 0.75rem;
        }
        .want-sooner-description {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.375rem;
          margin-bottom: 1.5rem;
        }
        .want-sooner-checkbox-container {
          display: flex;
          flex-direction: row;

          input {
            border-radius: 1.6px !important;
          }

          p {
            margin: 0 0 0 0.5rem;
          }
        }
        .want-sooner-preferred-date-container {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;

          &.hidden {
            display: none;
          }

          label {
            margin-bottom: 0.5rem;
            font-weight: 700;
            font-size: 1rem;
          }

          input {
            height: 2.2rem;
            border-radius: 0.5rem;
            border: 1px solid #acacac;
            margin-top: 0.25rem;
            padding: 0 1rem;
            outline: none;
            &::placeholder {
              color: #ACACAC;
            }
          }
        }
      }
    }
  }

  //WEB VIEW
  .stepTwo_webView_container {
    display: flex;
    flex-direction: column;
    // flex: 1;

    //ADDRESS
    .step_two_webView_yourAddress_container {
      display: flex;
      justify-content: space-between;
      .step_two_webView_yourAddress_left {
        flex: 0.675;
      }
      .step_two_webView_yourAddress_right {
        flex: 0.325;
        margin-left: 3.125rem;
      }
    }

    //CALLENDAR
    .step_two_webView_calendar_container {
      display: flex;
      //left side
      .step_two_webView_calendar_left {
        display: flex;
        flex-direction: column;
        flex: 0.675;

        .step_two_webView_calendar_buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 1.5625rem;
        }
      }
      //right side
      .step_two_webView_calendar_right {
        flex: 0.325;
        box-sizing: border-box;
        margin-top: 1.5625rem;
        margin-left: 3.125rem;
        flex-basis: start;

        h2 {
          line-height: 1.8rem;
          text-transform: capitalize;
        }
        p {
          margin-top: 0.9375rem;
        }

        .step_two_webView_calendar_slot_buttons {
          display: flex;
          flex-direction: column;
          gap: 0.9375rem;
          margin-top: 1.875rem;
        }
      }
    }
    .calendar-buttons-container {
      display: flex;
      .step_two_webView_calendar_buttons {
        display: flex;
        justify-content: space-between;
        flex: 0.675;
        margin-top: 1.5625rem;
        margin-right: 3.125rem;
      }
    }
    .stepTwo_want_sooner_container {
      display: flex;
      &.hidden {
        display: none;
      }
      .stepTwo-want-sooner-left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 0.675;
        margin-top: 1.5625rem;
        margin-right: 3.125rem;

        .want-sooner-title {
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5rem;
          margin-bottom: 0.75rem;
        }
        .want-sooner-description {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.375rem;
          margin-bottom: 1.5rem;
        }
        .want-sooner-checkbox-container {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.5rem;

          input {
            border-radius: 1.6px !important;
          }

          p {
            margin: 0 0 0 0.5rem;
          }
        }
        .want-sooner-preferred-date-container {
          display: flex;
          flex-direction: column;

          &.hidden {
            display: none;
          }

          label {
            margin-bottom: 0.5rem;
            font-weight: 700;
            font-size: 1rem;
          }

          input {
            border-radius: 8px;
            border: 1px solid #ACACAC;
            padding: 1rem;
            &::placeholder {
              color: #ACACAC;
            }
          }
        }
      }
    }
  }
}
