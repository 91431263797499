@import "utils/variables";

.career-navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 1px solid $black;
  position: fixed;
  width: calc(100% - 40px);
  z-index: 5;
  background: $white;

  @media (max-width: 925px) {
    padding: 0 20px;
    display: none;
  }

  .career-navbar-sub-container {
    display: flex;
    align-items: center;

    button {
      width: 220px;
    }
  }

  .career-navbar-sub-container:nth-of-type(1) {
    justify-content: space-between;
    width: 600px;

    .career-navbar-link-text {
      font-size: 16px;
      line-height: 22px;
      color: black;

      &:hover {
        cursor: pointer;
      }
    }

    .career-navbar-logo-container {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .career-navbar-logo-text {
        font-size: 25px;
        line-height: 22px;
        margin-left: 8px;
        color: black;
      }
    }
  }
}
