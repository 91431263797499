@import "utils/variables";

.bcarousel-container {
  margin: 1.5rem auto;

  .header-title {
    text-align: center;
    h2 {
      color: $spinach_green;
      font-size: 2.25rem;
      word-wrap: break-word;
      margin: 2rem auto;
      line-height: normal;

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      
      @media screen and (max-width: 768px) {
        margin-top: 1.5rem !important;
        font-size: 2rem;
        line-height: normal;
      }
    }
  }
}
