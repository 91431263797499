@import "utils/variables";

.how-we-hire-container {
  .how-we-hire-header {
    display: flex;
    justify-content: space-between;
    margin: 160px 40px 80px 120px;

    @media (max-width: 1350px) {
      flex-direction: column-reverse;
      margin: 160px 40px 80px;
    }

    @media (max-width: 600px) {
      margin: 10px;
      margin-top: 80px;
    }

    h1 {
      font-size: 64px;
      line-height: 72px;

      @media (max-width: 500px) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (max-width: 1350px) {
        margin: 40px 0;
      }

      @media (max-width: 600px) {
        margin: 10px 0;
      }

      p {
        width: 450px;
        font-size: 20px;
        line-height: 26px;
        margin-top: 40px;

        @media (max-width: 1350px) {
          margin: 20px 0;
        }

        @media (max-width: 600px) {
          margin: 20px 0;
          width: 100%;
        }
      }

      button {
        margin-top: 40px;
        width: 250px;
      }
    }
  }

  .how-we-hire-process {
    width: 900px;
    margin-bottom: 80px;
    margin-left: 120px;

    @media (max-width: 1050px) {
      margin: 40px;
      width: 90%;
    }

    @media (max-width: 600px) {
      margin: 40px 10px;
    }

    h2 {
      font-size: 48px;
      line-height: 56px;

      @media (max-width: 600px) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    p {
      font-size: 20px;
      line-height: 26px;
      margin: 10px 0;
    }
  }

  .how-we-hire-steps {
    margin-left: 120px;
    position: relative;
    z-index: 2;

    @media (max-width: 1000px) {
      margin-left: 40px;
    }

    @media (max-width: 560px) {
      margin-left: 10px;
    }

    @media (max-width: 400px) {
      margin-bottom: 80px;
    }

    .how-we-hire-step-container {
      display: flex;
      align-items: center;
      margin: 40px 0;
      position: relative;

      @media (max-width: 700px) {
        align-items: flex-start;
        margin: 20px 0;
      }

      .how-we-hire-text {
        width: 600px;
        padding-left: 60px;

        @media (max-width: 900px) {
          padding-left: 30px;
          width: 70%;
        }

        @media (max-width: 830px) {
          width: 80%;
        }

        @media (max-width: 500px) {
          padding-left: 10px;
        }

        @media (max-width: 500px) {
          padding-left: 2px;
        }

        h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }

      div::before {
        content: "";
        width: 2px;
        height: 100px;
        background-color: $black;
        position: absolute;
        left: 88px;
        top: 150px;
        z-index: 1;

        @media (max-width: 830px) {
          left: 37px;
          top: 52px;
          height: 270px;
        }

        @media (max-width: 700px) {
          height: 200px;
        }

        @media (max-width: 502px) {
          height: 250px;
        }

        @media (max-width: 410px) {
          height: 300px;
        }
      }

      div[aria-border]::before {
        content: "";
        height: 0;
      }

      .how-we-hire-desktop-number {
        position: relative;
        z-index: 2;

        @media (max-width: 830px) {
          display: none;
        }
      }

      .how-we-hire-mobile-number {
        display: none;
        position: relative;
        z-index: 2;
        width: 70px;

        @media (max-width: 830px) {
          display: block;
        }
      }
    }
  }

  .how-we-hire-positions {
    background: $purple;
    text-align: center;
    padding: 40px 0;
    margin: 40px 0;

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin: 0 20px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      width: 650px;
      margin: 40px auto;

      @media (max-width: 700px) {
        width: 90%;
      }
    }
  }

  .how-we-hire-faq {
    margin: 80px 0px 80px 120px;

    @media (max-width: 950px) {
      margin: 80px 40px;
    }

    @media (max-width: 560px) {
      margin: 80px 10px;
    }

    h2 {
      font-size: 48px;
      line-height: 56px;
    }
  }
}
