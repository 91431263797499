@import "utils/variables";

.why-choose-us-header {
  background-image: url("../../assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 25rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2.5rem;
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    text-align: center;
    margin: 0 2rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 46.25rem;
    text-align: center;
    margin-top: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.375rem;
      width: 90%;
    }

    p {
      margin: 0 auto;
    }
  }
}
.why-choose-us-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 75rem;

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: center;
    max-width: 15rem;
    margin: auto;
    padding: 3rem 0 0;
  }

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    width: 27.5rem;
    margin: 7rem 3rem 0;

    @media (max-width: 1024px) {
      text-align: center;
      padding: 2.5rem 1.25rem;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media (max-width: 768px) {
      width: 100%;
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 3rem 0;
      padding: 0;
    }
  }
}

.why-choose-us-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6.375rem 6.25rem;

  @media (max-width: 1024px) {
    margin: 6.375rem 3.125rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    margin: 2.375rem 1.5rem;
  }

  img {
    margin-right: 6.25rem;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;

    @media (max-width: 1024px) {
      margin-top: 2.5rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 2.5rem 0;
  }
}

.why-choose-us-badge-container {
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  .why-choose-us-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 49%;
    min-height: 25rem;
    border-radius: 1rem;
    padding: 1rem;

    @media (max-width: 1024px) {
      width: 98%;
    }

    img {
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
    }

    p {
      font-size: 1rem;
      line-height: 1.325rem;
      margin: 0.625rem 0 0;
      text-align: center;
      width: 30rem;

      @media (max-width: 480px) {
        width: 98%;
      }
    }
  }

  .why-choose-us-badge:nth-of-type(1) {
    margin: 0 1.25rem 0 0;

    @media (max-width: 1024px) {
      margin: 0 0 1.25rem 0;

    }
  }
}
