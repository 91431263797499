@import "utils/variables.scss";

.channel-package-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7.5rem;

  h2 {
    font-size: 3rem;
    font-weight: 300;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
    }
  }

  .channel-package-select {
    display: flex;
    margin-top: 5rem;

    p {
      font-size: 1.5rem;
      line-height: 2rem;
      max-width: 18.75rem;
      text-align: center;
      padding: 2rem 5rem;

      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
        max-width: 12.5rem;
        padding: 0.625rem;
      }

      @media (max-width: 500px) {
        width: 6.25rem;
      }

      &:hover {
        cursor: pointer;
      }
    }

    p[aria-selected="true"] {
      background: $light_green;
      color: $secondary_forest;
      border-radius: 1rem 1rem 0 0;
    }
  }

  .channel-select {
    background: $light_green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.25rem;
    border-radius: 1rem;

    h3 {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 0 0.625rem;
      text-align: center;
      max-width: 29.7rem;
    }

    .channel-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      height: 25rem;
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      .channel-gradient {
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 0.625rem;
        bottom: 0;
        z-index: 2;
        height: 12.5rem;
        width: 100%;
        border-radius: 1rem;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          $light_green 100%
        );

        &:hover {
          cursor: pointer;
        }

        p {
          color: #325a32;
          font-weight: 500;
          font-size: 1rem;
          margin-right: 0.375rem;
        }
      }

      .channel-gradient[aria-expanded="true"] {
        background: none;
        bottom: -2.5rem;
        z-index: 0;
      }
    }

    .channel-card-container[aria-expanded="true"] {
      height: 100%;
      margin-bottom: 2.5rem;
      overflow: visible;
    }

    .channel-text-container {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      margin: 1.25rem 0;

      p {
        margin-left: 0.625rem;
      }
    }
  }
}
