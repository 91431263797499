.homepage-badge-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0px auto 60px auto;

  @media screen and (max-width: 48rem) {
    margin: 0;
  }

  .homepage-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 320px;
    margin-top: 60px;

    img {
      height: 10rem;
      max-width: 100%;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      width: 340px;
      text-align: center;
    }
  }
}
