@import "utils/variables";

// EMAIL STEP
.stepThree_content_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1.5625rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 48rem) {
    margin-top: 0;
  }

  .stepThree_email_form {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    flex-direction: column;
    padding: 1.875rem;
    border: 0.125rem solid $btn_dark;
    border-radius: 22px;

    @media screen and (max-width: 48rem) {
      padding: 0;
      h2 {
        padding: 1rem 1rem 0 1rem;
      }
      hr {
        border: 1px solid black;
      }
      form {
        padding: 1rem;

        p {
          font-family: "WalsheimRegular";
        }
      }

      span {
        p {
          font-family: "WalsheimRegular";
          margin: 0.5rem 1rem 1rem 1rem;
        }
      }
    }

    &.MH {
      height: 8.75rem;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 0.625rem;
    }
    p {
      margin-bottom: 1.625rem;
    }
    .stepThree_error_text p {
      margin-bottom: 0;
    }
    .stepThree_email_wrapper {
      display: flex;
      align-items: flex-end;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        button {
          margin-top: 22px;
          width: 12.0625rem;
        }
      }

      .stepThree_email_input {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 21.875rem;
        margin-right: 1rem;

        label {
          color: $btn_dark;
          font-size: 0.75rem;
        }
      }
      .stepThree_email_input {
        .stepThree_input {
          height: 2.2rem;
          border-radius: 0.5rem;
          border: 1px solid #acacac;
          margin-top: 0.25rem;
          padding: 0 1rem;
          outline: none;
        }
        .error {
          border: 1px solid $error;
          background-color: $error_input_background;
        }
      }
    }
  }
  .stepThree_error_text {
    color: $error;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
  .stepThree_resend_container {
    display: flex;
    &.m2 {
      margin-top: 0.625rem;
    }
    .stepThree_resend_links {
      color: $secondary_forest;
      text-decoration: underline;
      font-size: 1rem;
      margin-top: 0.125rem;

      &:nth-child(2) {
        margin-left: 1.375rem;
      }
    }
  }
}
