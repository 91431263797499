@import "utils/variables";
$padding_left: 7.1875rem;
$padding_right: 1.4375rem;

.lifeatbeanfield_container {
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  margin: 0 auto;

  //DESCRIPTION SECTION
  .lifeatbeanfield_description {
    padding-left: $padding_left;
    padding-right: $padding_right;
    margin-top: 6.875rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 48rem) {
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 4.5rem;
    }

    .left_side {
      box-sizing: border-box;
      display: flex;
      flex: 0.45;
      flex-direction: column;
      padding-right: 5rem;

      h2 {
        font-size: 4rem;
        line-height: 4.5rem;
        margin-bottom: 1.25rem;
      }
      p {
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
      }

      @media screen and (max-width: 48rem) {
        padding-right: 0;
        h2 {
          font-size: 2rem;
          line-height: 2.5rem;
          margin-top: 1.4375rem;
          margin-bottom: 1.0625rem;
        }
        p {
          width: 100%;
        }
      }
    }

    .right_side {
      display: flex;
      flex: 0.8;
      flex-direction: column;

      img {
        width: 100%;
        border-radius: 1rem;
        max-height: 31.25rem;
      }
    }
  }

  // PART OF SOMETHING BIGGER SECTION
  .lifeatbeanfield_somethingBigger {
    padding-left: $padding_left;
    padding-right: $padding_right;
    margin-top: 5.3125rem;
    padding-right: $padding_left;

    @media screen and (max-width: 48rem) {
      margin-top: 2.9375rem;
    }

    h2 {
      text-align: center;
      font-size: 3rem;
      line-height: 3.5rem;
      margin-bottom: 5.3125rem;
    }
    h3 {
      text-align: start;
      margin-top: 1.1875rem;
      font-size: 2rem;
      line-height: 3rem;

      @media screen and (max-width: 48rem) {
        margin-top: 1rem;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    p {
      font-size: 1rem;
      width: 50%;
      line-height: 1.375rem;
      text-align: start;
      margin-top: 0.6875rem;
    }
    .office_image {
      margin-top: 5.3125rem;
      width: 100%;

      @media screen and (max-width: 48rem) {
        margin-top: 1.5rem;
      }
    }

    .player {
      margin-top: 4.6875rem;
      height: 39.375rem;

      @media screen and (max-width: 48rem) {
        margin-top: 3.125rem;
        height: 11.5625rem;
      }
    }

    .diversity {
      display: flex;
      margin-top: 1.9375rem;
      gap: 3.4375rem;
      //   justify-content: space-between;

      @media screen and (max-width: 48rem) {
        flex-direction: column;
        margin-top: 1rem;
        gap: 1.375rem;
      }

      .diversity_left {
        display: flex;
        flex-direction: column;
        flex: 0.5;

        h3 {
          text-align: start;
          margin-top: 0;
        }
        p {
          text-align: start;
          margin-top: 0.6875rem;
          line-height: 1.375rem;
          width: 100%;
        }
      }
      .diversity_right {
        display: flex;
        flex-direction: row;
        flex: 0.5;
        background-color: #eafcc3;
        border-radius: 1rem;

        @media screen and (max-width: 48rem) {
          flex-direction: column;
          padding-left: 1.5rem;
        }

        .image {
          padding: 1.5rem 1.875rem;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 48rem) {
            padding: 1.5rem 0;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .bg {
            margin-right: 1.5625rem;
            width: 6.625rem;
            height: 5.3125rem;
            background-image: url("../../assets/LifeAtBeanfield/RainbowRegistered_Alt_Col\ 1.png");
            background-repeat: no-repeat;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: optimizeQuality;
          }
          img {
            max-height: 5.3125rem;
          }
        }
        .title_text {
          padding: 1.5rem 1.875rem 1.5rem 0;

          p {
            font-size: 0.75rem;
            margin-top: 0;
            line-height: 1rem;

            b {
              margin: 0;
              padding: 0;
              line-height: normal;
            }
          }
          a {
            font-size: 0.75rem;
            color: $secondary_forest;

            @media screen and (max-width: 48rem) {
              float: left;
              margin-top: 1.375rem;
            }
          }

          @media screen and (max-width: 48rem) {
            padding: 0rem 1.875rem 1.5rem 0;
            margin: 0;
          }
        }
        h3 {
          text-align: start;
          margin-top: 0;
        }
        p {
          text-align: start;
          margin-top: 0.6875rem;
          width: 100%;
        }
      }
    }

    .connections_resource_container {
      display: flex;
      flex-direction: column;
      gap: 3.4375rem;
      margin-top: 4.6875rem;

      @media screen and (max-width: 48rem) {
        margin-top: 3.375rem;
      }

      .tbd_photos_container {
        display: flex;
        gap: 3.4375rem;

        @media screen and (max-width: 48rem) {
          flex-direction: column;
        }

        .tbd_photo_wrapper {
          display: flex;
          flex-direction: column;
          flex: 0.5;

          .bg {
            width: 100%;
            border-radius: 1rem;
            height: 25rem;
            background-color: gray;

            @media screen and (max-width: 48rem) {
              height: 12rem;
            }
          }

          p {
            width: 100%;
          }

          .connections_resource_right_side {
            .ul_lists {
              display: flex;
              flex-direction: row;
              font-family: "WalsheimBold";
              margin-top: 0.6875rem;

              @media screen and (max-width: 48rem) {
                text-align: start;
                float: left;
                margin-top: 1.6875rem;
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 48rem) {
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;

      h2 {
        width: 80%;
        margin: 0 auto;
        align-self: center;
        font-size: 2rem !important;
        line-height: 2.5rem;
        margin-bottom: 1.375rem;
      }
      p {
        width: 100%;
      }
    }
  }
}

// INSTAGRAM FEED SLIDER

.instagram-outer-container {
  width: 100%;
  margin: 4rem 0;
  background-color: $instagram_feed_background;
  @media screen and (max-width: 48rem) {
    padding-bottom: 2rem;
  }

  .inner-container {
    display: flex;
    flex: 1;
    margin: 0rem auto;
    gap: 0.625rem;
    max-width: 90rem;
    box-sizing: border-box;

    @media screen and (max-width: 48rem) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 0;
      margin-right: 0;
      margin-left: 1.9375rem;
      margin-top: 1.875rem;
      box-sizing: border-box;
    }

    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    p {
      margin-top: 0.8125rem;
    }

    .left {
      flex: 0.3;
      // width: 300px;
      box-sizing: border-box;
      // width: 100%;
      padding-left: 7.25rem;
      margin-right: 1rem;
      padding-top: 1.125rem;
      padding-bottom: 1.5rem;

      @media screen and (max-width: 48rem) {
        padding-left: 0;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
        width: 100%;
        max-width: 100%;
        h2 {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        p {
          width: 80%;
        }
      }
    }

    .right {
      flex: 0.7;
      box-sizing: border-box;
      margin-bottom: 1rem;
      margin-top: 1rem;
      // width: 70%;

      @media screen and (max-width: 48rem) {
        width: 100%;
        max-width: 100%;
        margin-right: 2rem;
      }

      .carousel-dots-container {
        display: flex;
        gap: 5px;
        position: absolute;
        bottom: -30px;
        left: 42.5%;

        .carousel-dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          border: 1px solid rgba(75, 74, 74, 1);
        }
      }

      .blog-carousel-card {
        display: flex;
        flex-direction: column;

        img {
          width: 275px;
          height: 300px;
          max-width: 275px;
          max-height: 300px;
          object-fit: cover;
          border-radius: 1.375rem;
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
