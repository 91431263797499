@import "utils/variables";

.search-result-item-container {
  border-bottom: 1px solid $gray;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }

  h2 {
    color: $secondary_forest;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .search-result-path {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .search-result-text {
    font-size: 16px;
    line-height: 22px;
    width: 700px;
  }
}
