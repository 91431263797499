@import "utils/variables";

.questionnaire-slide-two-modal-container {
  padding: 60px 80px;
  width: 50rem;
  box-sizing: border-box;

  @media (max-width: 730px) {
    padding: 40px 30px;
    width: 100%;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .slide-two-question-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1rem 0 1.875rem;

    .radio_btn_wrapper {
      display: flex;

      label {
        margin-top: 0.225rem;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      margin-left: 10px;
    }
  }

  input,
  label {
    &:hover {
      cursor: pointer;
    }
  }
}
