@import "utils/variables";

.about-us-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0 5rem;
  flex: 1;

  .about-us-header-wrapper {
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
    }
  }

  .about-us-header-content {
    h1 {
      font-size: 4rem;
      line-height: 4.5rem;
      margin-bottom: 2.5rem;

      @media (max-width: 1200px) {
        font-size: 3rem;
      }

      @media (max-width: 1024px) {
        width: 96%;
        margin: 1rem auto;
      }

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.25rem;
      line-height: 1.625rem;
      max-width: 34.375rem;

      @media (max-width: 1024px) {
        width: 96%;
        max-width: 100%;
        margin: 1rem auto;
      }
    }
  }

  .about-us-video-container {
    width: 60%;
    margin: 0 0 0 1rem;

    @media (max-width: 1024px) {
      box-sizing: border-box;
      padding: 0;
      height: auto;
      width: 100%;
      margin: 0;
    }
  }
}

.about-us-quote-container {
  background: $yellow;
  padding: 5rem 0;
  text-align: center;

  p {
    color: $secondary_forest;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  p:nth-of-type(1) {
    font-size: 2rem;
    line-height: 2.5rem;
    max-width: 60rem;
    margin: 1.875rem auto;
    color: $black;

    @media (max-width: 768px) {
      width: 90%;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}

.about-us-badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem auto;

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    text-align: center;
  }
}

.about-us-carousel-header {
  text-align: center;

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 43.75rem;
    margin: 1.875rem auto;

  }
}

.about-us-blog-container {
  .page-link-container {
    margin: 0px !important;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .about-us-blog-content-container {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;
    overflow: hidden;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }

    .about-us-blog-content {
      display: flex;
      flex-direction: column;
      margin: 1.25rem 0;
      width: 25%;

      @media (max-width: 768px) {
        margin-top: 1.75rem;
        width: 100%;
      }
    }
  }
}

.about-us-work-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 1.875rem;
    text-align: center;
  }

  .about-us-box-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .about-us-box {
      border: 0.125rem solid $black;
      box-sizing: border-box;
      border-radius: 1.375rem;
      display: flex;
      flex-direction: column;
      align-items: center;


      img {
        width: 100%;
        border-radius: 1rem 1rem 0 0;
        object-fit: cover;
      }

      .about-us-box-content {
        padding: 1.25rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1.5rem;
          line-height: 2rem;
          margin-bottom: 0.625rem;
        }
      }
    }

    .about-us-box:nth-last-of-type(1) {
      margin: 1.25rem 0 1.25rem 1.25rem;

      @media (max-width: 1024px) {
        margin: 1.25rem 0;
      }
    }
    .about-us-box:nth-of-type(1) {
      margin: 1.25rem 1.25rem 1.25rem 0;

      @media (max-width: 1024px) {
        margin: 1.25rem 0;
      }
    }
  }
}
