@import "utils/variables";

.go-banner {
  background-position: left 1rem top 0;
  @media (min-width: 48.0625rem) and (max-width: 64rem) {
    background-position: right -0.5rem bottom 0;
    background-size: cover;
  }
  @media (max-width: 48rem) {
    background-position: right -0.5rem bottom 0;
    background-size: cover;
    box-shadow: unset;
  }
}
.go-tv-banner-container {
  padding: 5rem 0 0 2rem;

  @media (max-width: 48rem) {
    padding: 3.75rem 1.25rem;
  }

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 0.75rem;

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 25rem;

    @media (max-width: 48rem) {
      font-size: 1.25rem;
      line-height: 1.625rem;
      width: 100%;
    }
  }
}

.go-text-container {
  text-align: center;
  margin: 5rem auto;
  max-width: 70rem;

  @media (max-width: 48rem) {
    text-align: left;
    max-width: 100%;
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 0 0.625rem;

    @media (max-width: 48rem) {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 1.875rem auto;
    max-width: 58rem;
  }

  a {
    color: $secondary_forest;

    &:hover {
      cursor: pointer;
    }
  }
}

.go-card-container {
  background: $light_green;

  .go-card-content {
    display: flex;
    padding: 2.5rem 0;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 48rem) {
      flex-direction: column;
    }
  }
}
