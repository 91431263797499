@import "utils/variables";

.email-modal-container {
  padding: 80px 80px 10px 80px;
  display: flex;
  flex-direction: column;

  @media (max-width: 730px) {
    padding: 40px 10px;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
    margin-left: 1.25rem;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0;
    width: 500px;
    box-sizing: border-box;

    @media (max-width: 600px) {
      width: 100%;

      &:first-of-type {
        padding-right: 10px;
      }
    }
  }
  .email-modal-reminder {
    p {
      margin-top: 0.625rem;
      text-align: center;
      font-size: 0.75rem;
    }
  }
  .email-modal-header {
    p {
      margin-left: 1.25rem;
    }
  }

  .email-modal-subtitle {
    margin-left: 20px;

    span {
      color: $secondary-forest;
    }
  }

  .email-modal-copy-container {
    background: #f2f2f2;
    padding: 20px;
    border-radius: 22px;
    margin-bottom: 20px;
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.7);
    .email-modal-border-container {
      border-bottom: 1px solid #9d9d9d;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  .email-modal-button-container {
    display: flex;
    justify-content: flex-end;

    .email-modal-copy-button {
      background: $white;
      color: $secondary-forest;
      border: 2px solid $secondary-forest;
      border-radius: 22px;
      padding: 5px 25px;
      margin-right: 20px;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
      }
    }

    .email-modal-copy-button[aria-pressed="true"] {
      background: $secondary-forest;
      color: $white;
      border: 2px solid $secondary-forest;
    }
  }

  .email-modal-empty-container {
    height: 42px;
  }

  .email-modal-cc-text {
    position: relative;
    left: 70px;
    top: 10px;
    strong {
      color: $secondary-forest;
    }
  }
}
