@import "utils/variables";

.public-header {
  background-image: url("../../assets/backgrounds/gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 25rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2.5rem;
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 3.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 46.25rem;
    text-align: center;
    margin-top: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.375rem;
      width: 90%;
    }

    p {
      margin: 0 auto;
    }
  }
}

.public-wifi-map {
  max-width: 90%;
  margin: 0 auto;
  height: 37.5rem;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 1.375rem;
  }
}

.public-subtitle {
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  max-width: 90%;
  margin: 3.75rem auto;
  font-family: WalsheimBold, serif;
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    max-width: 100%;
  }
}

.public-card-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 90%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .public-card {
    border: 0.125rem solid $black;
    max-width: 30rem;
    border-radius: 1rem;
    padding: 2.5rem;
    width: 45%;

    @media (max-width: 768px) {
      padding: 1.25rem;
      width: unset;
      max-width: 100%;
    }

    .public-card-subtitle {
      font-size: 1.25rem;
      line-height: 1.625rem;
    }

    h2 {
      font-family: WalsheimBold, serif;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    a {
      font-family: WalsheimBold, serif;
      font-weight: 300;
    }

    .public-card-text {
      margin: 2.5rem 0;

      p:nth-child(2) {
        margin-top: 1.25rem;
      }
    }

    .public-card-info {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #e6f0ec;
      border-radius: 1rem;
      padding: 1.25rem;

      p {
        width: 90%;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  .public-card:nth-child(1) {
    margin-right: 0.625rem;

    @media (max-width: 768px) {
      margin: 0 auto 1.5rem;
    }
  }
  .public-card:nth-child(2) {
    margin-left: 0.625rem;

    @media (max-width: 768px) {
      margin: 1.5rem auto 0;
    }
  }
}
