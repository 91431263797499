@import "utils/variables.scss";

.channel-card {
  background: #fff;
  width: 30%;
  border-radius: 1rem;
  margin: 0.625rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 18.75rem;
  z-index: 1;

  @media (max-width: 768px) {
    width: unset;
  }

  .channel-card-text {
    min-height: 6.625rem;
    border-bottom: #201f1f 1px solid;
  }

  p:not(.channel-card-all-channels-text) {
    font-size: 4rem;
    line-height: 4.5rem;
    color: $secondary_forest;
    position: relative;
    padding: 0.625rem;
    text-align: center;

    span {
      color: $secondary_forest;
      font-size: 1.25rem;
      line-height: 1.5rem;
      position: relative;
      font-family: "WalsheimRegular";
    }

    span:nth-of-type(1) {
      top: -1.875rem;
      font-family: "WalsheimBold" !important;
    }

  }

  .channel-card-star {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 0 0.625rem;
  }

  .channel-card-image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
    .image-logo {
      width: 30%;
      margin: 1rem 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
  }

  .channel-card-all-channels-text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary_forest;
    text-align: center;
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    left: 0;
    margin: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
