@import "utils/variables.scss";

.theme-card {
  background: #fff;
  border-radius: 1rem;
  display: flex;
  margin: 1rem 1rem;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  width: 30%;
  min-height: 18.75rem;
  z-index: 1;

  @media (max-width: 768px) {
    width: unset;
  }

  .theme-card-text {
    min-height: 6.625rem;
    border-bottom: #201f1f 1px solid;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 0.625rem;
    max-width: 16rem;
  }

  div {
    width: 100%;
    padding: 0.625rem;
    box-sizing: border-box;
  }

  .theme-card-star {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }

  .theme-card-channel-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    .theme-card-image-container {
      width: 30%;
      height: 2.5rem;
      margin: 1rem 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
  }

  .theme-card-all-channels-text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary_forest;
    text-align: center;
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    left: 0;
    margin: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
