@import "utils/variables";

.orderPage_right_total_wrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: 1254px) {
    padding: 2rem;
  }
  h2 {
    margin-bottom: 1rem;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.2rem 0;

    &:last-child {
      margin: 0;
    }
    h2 {
      margin: 0;
      padding: 0;
    }
  }

  .orderPage_right_deviderLine {
    border: 1px solid $black;
    opacity: 0.25;
    margin: 0.5rem 0;
  }
}
