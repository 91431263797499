@import "utils/variables";

.review-carousel-container {
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }

  .slick-prev {
    z-index: 1;
    left: 10px;
    top: 140px;
    opacity: 0;
    transition: 0.5s;

    &::before {
      display: none;
    }
  }

  .slick-next {
    z-index: 1;
    right: 45px;
    top: 140px;
    opacity: 0;
    transition: 0.5s;

    &::before {
      display: none;
    }
  }

  .review-carousel {
    display: flex;
    flex-direction: column;
    background: #eafcc3;
    border-radius: 22px;
    padding: 20px;
    border: 10px solid $white;
    height: 19.5rem;
    justify-content: space-between;

    @media (max-width: 480px) {
      height: 30rem;
    }

    .review-stars {
      display: flex;
      gap: 0.3125rem;
      margin-bottom: 1.25rem;
    }

    @media (max-width: 768px) {
      border: 5px solid $white;
    }
  }

  .review-carousel-text {
    font-size: 16px;
    line-height: 22px;
  }
  .review-carousel-footer {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  .review-carousel-stacked-footer {
    display: flex;
    flex-direction: column;
  }

  .review-carousel-name {
    font-size: 16px;
    line-height: 22px;
  }

  .review-carousel-location {
    font-size: 12px;
    line-height: 16px;
  }
}
