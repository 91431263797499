@import "utils/variables";

.text-title-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  .title-text {
    margin: 1.75rem 0;

    @media (min-width: 769px) and (max-width: 1024px) {
      margin: 1.5rem 0;
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
    }

    h2 {
      font-size: 2.25rem;
      line-height: normal;
      color: $spinach_green;
      font-family: "WalsheimBold", serif;
      text-align: center;

      @media (min-width: 769px) and (max-width: 1024px) {
        font-size: 2rem;
        line-height: normal;
      }

      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: normal;
        text-align: center;
      }
    }
  }

  .text-title-description {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      margin: 0 0 0.5rem;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 0 0.5rem;
    }
  }
}
