@import "utils/variables";

.two-column-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;

  .title-text {
    margin: 1.75rem 0;

    @media (min-width: 769px) and (max-width: 1024px) {
      margin: 1.5rem 0;
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
    }

    h2 {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: $spinach_green;
      font-family: 'WalsheimBold', sans-serif;

      @media (max-width: 1024px) {
        font-size: 2rem;
        line-height: normal;
      }
    }
  }

  .items-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .item-container {
      width: 50%;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        width: unset;
      }

      .image {
        margin-bottom: 3rem;

        img {
          border-radius: 0.625rem;
          width: 100%;
          height: auto;
          max-height: 23rem;
          object-fit: cover;
        }
      }
      .content {
        @media (max-width: 768px) {
          margin-bottom: 3rem;
        }
        p {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          text-align: justify;
          letter-spacing: -0.015rem;

          @media (max-width: 768px) {
            text-align: center;
          }
        }
      }
    }
  }

}
