@import "utils/variables";

.marquee-card-container {
  margin: 0 0.625rem 3rem;
  position: relative;

  img {
    border-radius: 0 0 1rem 1rem;
    margin: 1.25rem 0;
  }

  p {
    background: $white;
    padding: 0.375rem 0;
    position: absolute;
    z-index: 1;
    bottom: 0;
    border-radius: 0 0 1rem 1rem;
    text-align: center;
  }
}
