@import "utils/variables";

.old-residential-container {
  width: 40rem;
  text-align: center;
  padding: 4rem 4rem 2rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 2.5rem 0.625rem;
    width: 100%;
  }

  h2 {
    font-size: 1.625rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;
  }

  .old-residential-body {
    p {
      margin-bottom: 1rem;
    }
    .old-residential-text {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary_forest;
      margin-top: 2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0 1.25rem;

    @media screen and (max-width: 768px) {
      padding: 0 0.35rem;
    }

    a {
      color: $secondary-forest;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
