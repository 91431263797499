@import "utils/variables";

.overflow-carousel-container {
  .header-title {
    @media (max-width: 48rem) {
      max-width: 17rem;
      margin: auto;
    }
  }
  .carousel-item {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 52.5625rem !important;
    margin: 0 3rem !important;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1.375rem;
    margin-bottom: 1rem !important;

    @media screen and (max-width: 48rem) {
      width: 21.5rem !important;
      margin: 0 0.5rem !important;
      height: 24rem !important;
      margin-bottom: 1rem !important;
      justify-content: flex-start !important;
    }

    .carousel-image {
      width: 100%;
      min-height: 30.25rem !important;
      overflow: hidden;
      border-radius: 1.375rem 1.375rem 0 0;

      img {
        margin: 0 auto;
      }

      @media screen and (max-width: 48rem) {
        min-height: 13.25rem !important;
        img {
          height: 13.25rem !important;
        }
      }
    }
    .carousel-titles {
      margin: 0 auto;
      width: 90%;
      min-height: 8rem;

      @media screen and (max-width: 48rem) {
        height: 9.5rem !important;
      }

      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
        text-align: center;
        margin: 1.25rem 0 0 0 !important;

        @media screen and (max-width: 48rem) {
          margin-top: 1.5rem !important;
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
      p {
        font-size: 1.25rem;
        line-height: 1.625rem;
        text-align: center;
        margin: 1em;

        @media screen and (max-width: 48rem) {
          font-size: 1rem;
        }
      }
    }
  }
  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    text-align: center;
    margin-top: 5rem;

    @media screen and (max-width: 48rem) {
      font-size: 2.125rem;
      line-height: 2.5rem;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 3.125rem;

    @media screen and (max-width: 48rem) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .slick-dots li button:before {
    font-size: 0;
    border: 0.125rem solid $btn_black;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: $white;
    top: 0.75rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    background-color: $btn_black;
    opacity: 1;
  }
}
