@import "utils/variables";

.step_four_container {
  display: flex;
  flex: 1;
  box-sizing: border-box;

  @media screen and (max-width: 1250px) {
    flex: 1;
    flex-direction: column;
    margin-top: 3rem;
  }

  @media screen and (max-width: 48rem) {
    margin-top: 7rem;
    padding: 0 0 2rem 1rem;
    font-family: "WalsheimRegular";
  }

  .step_four_sub_container {
    flex: 0.7;

    @media screen and (max-width: 1250px) {
      flex: 1;
    }

    .step_four_time_error {
      display: flex;
      color: $error;
      background-color: $error_background;
      padding: 1.5rem 1rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
      align-items: center;
      justify-content: space-between;

      .left_content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
      }
      .right_content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        p {
          margin-left: 1.5rem;
          width: 60%;
        }

        span {
          color: $secondary_forest;
          width: 30%;
          min-width: 200px;
          &:hover {
            cursor: pointer;
          }
          text-align: right;
        }
      }

      @media screen and (max-width: 48rem) {
        padding: 1.5rem 2rem;

        .left_content {
          display: flex;
          flex: 1;
          height: 100%;
          justify-content: center;
          align-items: center;
          margin-left: 0;
        }
        .right_content {
          display: flex;
          flex-direction: column;
          margin-left: 1rem;

          p {
            margin-left: 0;
            width: 100%;
          }
          span {
            margin-top: 0.35rem;
            width: 100%;
            text-align: left;
          }
        }
      }
    }

    .step_four_left_wrapper {
      flex: 0.7;
      border: 2px solid $border_dark;
      border-radius: 1.375rem;
      padding: 1.25rem 2.1875rem;
      align-self: flex-start;

      @media screen and (max-width: 48rem) {
        flex: 1;
        padding: 0;
        h2 {
          padding: 1rem;
        }
        .left_sub_wrap {
          padding: 1rem 1rem;
        }
        .top_devider {
          border: 1px solid $border_dark;
        }
      }

      .left_sub_wrap {
        .sub_title {
          margin-top: 1rem;
          margin-bottom: 1.25rem;

          @media screen and (max-width: 48rem) {
            margin-top: 0.5rem;
          }
        }

        .block_container {
          display: flex;
          flex-direction: column;
          margin-top: 0.65rem;

          @media screen and (max-width: 48rem) {
            margin-top: 1.25rem;
          }

          .block_title {
            display: flex;

            h4 {
              margin-right: 0.3125rem;
              font-size: 1rem;
            }
            span {
              color: $secondary_forest;
              text-decoration: underline;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .devider_line {
        margin-top: 2rem;
      }
      .step_four_billing_checkbox {
        margin-top: 1.375rem;
        display: flex;
        flex-direction: row;

        a {
          color: $secondary_forest;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }

        input {
          border-radius: 1.6px !important;
          margin-top: 0.2rem;
        }

        .checkmark {
          margin-top: 0.2rem;
        }
        p {
          margin: 0 0 0 1rem;
        }
      }
    }

    .step_four_buttons {
      display: flex;
      margin-top: 1rem;
      justify-content: space-between;
    }
  }
}
