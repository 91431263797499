@import "utils/variables";

.image-package-container {
  border: 0.125rem solid $black;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  width: 37.5rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1.25rem 0 1rem;

    @media (max-width: 480px) {
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;

    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  .image-package-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }

  .page-link-container {
    margin-top: 1rem;
  }
}

.package1 {
  grid-area: 1 / 1 / 3 / 2;
}

.package2 {
  grid-area: 1 / 2 / 2 / 3;
}

.package3 {
  grid-area: 2 / 2 / 3 / 3;
}
